import { FC } from "react";
import { HouseScreenControlItem } from "./house-screen-control-item";

export const HouseLeftBar: FC = () => {
  return (
    <div className="house-left-bar fixed top-0 left-0 h-screen flex flex-col gap-8 px-12 justify-center items-start">
      <HouseScreenControlItem name="Крыша" model="крыша" code={1} />
      <HouseScreenControlItem
        name="Межэтажное перекрытие"
        model="межэтажное"
        code={2}
      />
      <HouseScreenControlItem name="Стены" model="стены" code={3} />
      <HouseScreenControlItem name="Полы по грунту" model="полы" code={4} />
      <HouseScreenControlItem name="Цоколь" model="цоколь" code={5} />
      <HouseScreenControlItem name="Фундамент" model="фундамент" code={6} />
    </div>
  );
};
