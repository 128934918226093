import { useDispatch, useSelector } from "app/store";

import { Model as F00 } from "../meshes/f00";
import { Box } from "./box";
import { Model as F01 } from "../meshes/f01";
import { Model as F02 } from "../meshes/f02";
import { Model as F10 } from "../meshes/f10";
import { Model as F11 } from "../meshes/f11";
import { Model as F12 } from "../meshes/f12";
import { Model as F20 } from "../meshes/f20";
import { Model as F21 } from "../meshes/f21";
import { Model as F22 } from "../meshes/f22";

import { Model as walls00 } from "../meshes/walls00";
import { Model as walls01 } from "../meshes/walls01";
import { Model as walls02 } from "../meshes/walls02";
import { Model as walls10 } from "../meshes/walls10";
import { Model as walls11 } from "../meshes/walls11";
import { Model as walls12 } from "../meshes/walls12";
import { Model as walls20 } from "../meshes/walls20";
import { Model as walls21 } from "../meshes/walls21";
import { Model as walls22 } from "../meshes/walls22";
import { Model as walls30 } from "../meshes/walls30";
import { Model as walls31 } from "../meshes/walls31";
import { Model as walls32 } from "../meshes/walls32";

import { Model as floors00 } from "../meshes/floors00";
import { Model as floors01 } from "../meshes/floors01";
import { Model as floors02 } from "../meshes/floors02";
import { Model as floors10 } from "../meshes/floors10";
import { Model as floors11 } from "../meshes/floors11";
import { Model as floors12 } from "../meshes/floors12";
import { Model as floors20 } from "../meshes/floors20";
import { Model as floors21 } from "../meshes/floors21";
import { Model as floors22 } from "../meshes/floors22";
import { Model as floors30 } from "../meshes/floors30";
import { Model as floors31 } from "../meshes/floors31";
import { Model as floors32 } from "../meshes/floors32";

import { Model as interfloor00 } from "../meshes/interfloor00";
import { Model as interfloor01 } from "../meshes/interfloor01";
import { Model as interfloor02 } from "../meshes/interfloor02";
import { Model as interfloor10 } from "../meshes/interfloor10";
import { Model as interfloor11 } from "../meshes/interfloor11";
import { Model as interfloor12 } from "../meshes/interfloor12";
import { Model as interfloor20 } from "../meshes/interfloor20";
import { Model as interfloor21 } from "../meshes/interfloor21";
import { Model as interfloor22 } from "../meshes/interfloor22";
import { Model as interfloor30 } from "../meshes/interfloor30";
import { Model as interfloor31 } from "../meshes/interfloor31";
import { Model as interfloor32 } from "../meshes/interfloor32";
import { Model as interfloor40 } from "../meshes/interfloor40";
import { Model as interfloor41 } from "../meshes/interfloor41";
import { Model as interfloor42 } from "../meshes/interfloor42";

import { Model as roof00 } from "../meshes/roof00";
import { Model as roof01 } from "../meshes/roof01";
import { Model as roof02 } from "../meshes/roof02";

import { Model as roof10 } from "../meshes/roof10";
import { Model as roof11 } from "../meshes/roof11";
import { Model as roof12 } from "../meshes/roof12";

import { Model as roof20 } from "../meshes/roof20";
import { Model as roof21 } from "../meshes/roof21";
import { Model as roof22 } from "../meshes/roof22";

import { Model as roof30 } from "../meshes/roof30";
import { Model as roof31 } from "../meshes/roof31";
import { Model as roof32 } from "../meshes/roof32";

import { Model as roof40 } from "../meshes/roof40";
import { Model as roof41 } from "../meshes/roof41";
import { Model as roof42 } from "../meshes/roof42";

import { Model as roof50 } from "../meshes/roof50";
import { Model as roof51 } from "../meshes/roof51";
import { Model as roof52 } from "../meshes/roof52";

import { Model as roof60 } from "../meshes/roof60";
import { Model as roof61 } from "../meshes/roof61";
import { Model as roof62 } from "../meshes/roof62";

import { Model as roof70 } from "../meshes/roof70";
import { Model as roof71 } from "../meshes/roof71";
import { Model as roof72 } from "../meshes/roof72";

import { Model as roof80 } from "../meshes/roof80";
import { Model as roof81 } from "../meshes/roof81";
import { Model as roof82 } from "../meshes/roof82";

import { Model as roof90 } from "../meshes/roof90";
import { Model as roof91 } from "../meshes/roof91";
import { Model as roof92 } from "../meshes/roof92";

import { Model as roof100 } from "../meshes/roof100";
import { Model as roof101 } from "../meshes/roof101";
import { Model as roof102 } from "../meshes/roof102";

import { Model as roof110 } from "../meshes/roof110";
import { Model as roof111 } from "../meshes/roof111";
import { Model as roof112 } from "../meshes/roof112";

import { Model as roof120 } from "../meshes/roof120";
import { Model as roof121 } from "../meshes/roof121";
import { Model as roof122 } from "../meshes/roof122";

import { Model as base00 } from "../meshes/base00";
import { Model as base01 } from "../meshes/base01";

import { Center, Resize } from "@react-three/drei";
import { FC } from "react";
import { GroupProps } from "@react-three/fiber";
import { bases, roofs } from "./primitive-model";
import { PI } from "app/math";

const models = {
  фундамент00: F00,
  фундамент01: F01,
  фундамент02: F02,
  фундамент10: F10,
  фундамент11: F11,
  фундамент12: F12,
  фундамент20: F20,
  фундамент21: F21,
  фундамент22: F22,

  стены00: walls00,
  стены01: walls01,
  стены02: walls02,
  стены10: walls10,
  стены11: walls11,
  стены12: walls12,
  стены20: walls20,
  стены21: walls21,
  стены22: walls22,
  стены30: walls30,
  стены31: walls31,
  стены32: walls32,

  полы00: floors00,
  полы01: floors01,
  полы02: floors02,
  полы10: floors10,
  полы11: floors11,
  полы12: floors12,
  полы20: floors20,
  полы21: floors21,
  полы22: floors22,
  полы30: floors30,
  полы31: floors31,
  полы32: floors32,

  межэтажное00: interfloor00,
  межэтажное01: interfloor01,
  межэтажное02: interfloor02,
  межэтажное10: interfloor10,
  межэтажное11: interfloor11,
  межэтажное12: interfloor12,
  межэтажное20: interfloor20,
  межэтажное21: interfloor21,
  межэтажное22: interfloor22,
  межэтажное30: interfloor30,
  межэтажное31: interfloor31,
  межэтажное32: interfloor32,
  межэтажное40: interfloor40,
  межэтажное41: interfloor41,
  межэтажное42: interfloor42,

  крыша00: roof00,
  крыша01: roof01,
  крыша02: roof02,

  крыша10: roof10,
  крыша11: roof11,
  крыша12: roof12,

  крыша20: roof20,
  крыша21: roof21,
  крыша22: roof22,

  крыша30: roof30,
  крыша31: roof31,
  крыша32: roof32,

  крыша40: roof40,
  крыша41: roof41,
  крыша42: roof42,

  крыша50: roof50,
  крыша51: roof51,
  крыша52: roof52,

  крыша60: roof60,
  крыша61: roof61,
  крыша62: roof62,

  крыша70: roof70,
  крыша71: roof71,
  крыша72: roof72,

  крыша80: roof80,
  крыша81: roof81,
  крыша82: roof82,

  крыша90: roof90,
  крыша91: roof91,
  крыша92: roof92,

  крыша100: roof100,
  крыша101: roof101,
  крыша102: roof102,

  крыша110: roof110,
  крыша111: roof111,
  крыша112: roof112,

  крыша120: roof120,
  крыша121: roof121,
  крыша122: roof122,

  цоколь00: base00,
  цоколь01: base01,
};

const hoc = (C: FC<GroupProps>) => () =>
  (
    <Resize>
      <Center>
        <C />
      </Center>
    </Resize>
  );
for (let x of Object.keys(models)) {
  // @ts-ignore
  models[x] = hoc(models[x]);
}

export const ModelSelector = () => {
  const dispatch = useDispatch();
  const { currentModel, currentVariant, currentTier } = useSelector(s => s.ui);
  const C =
    models[
      `${currentModel}${currentVariant}${currentTier}` as unknown as keyof typeof models
    ];
  if (C) {
    return (
      <group position={[0, -1.35, 0]} scale={2} rotation={[0, PI, 0]}>
        <C />
      </group>
    );
  } else {
    return <Box color="mediumseagreen" position={[0, -1.5, 0]} />;
  }
};
