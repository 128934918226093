import { FC, ReactNode } from "react";
import c from "classnames";

interface Props {
  children?: ReactNode;
  collapsed?: boolean;
}

export const Collapse: FC<Props> = ({ collapsed, children }) => {
  return (
    <div
      className={c("collapse-component overflow-hidden transition-all", {
        "h-0": collapsed,
        "h-auto": !collapsed,
      })}
    >
      {children}
    </div>
  );
};
