import { FC } from "react";
import ReactSlider from "react-slider";

interface Props {
  min: number;
  max: number;
  onChange: (e: number[]) => void;
  defaultValue: number[];
}

export const FilterRange: FC<Props> = ({
  min,
  max,
  defaultValue,
  onChange,
}) => {
  return (
    <div className="px-3">
      <ReactSlider
        onChange={onChange}
        className="pt-11 pb-3"
        thumbClassName="cursor-pointer position-relative md:hover:opacity-75 slider-thumb"
        renderThumb={(props, state) => (
          <div {...props}>
            <div className="finger-catcher absolute -left-3 -bottom-3 w-6 h-8 bg-transparent"></div>
            <div className="absolute -left-4 bottom-3 bg-transparent font-display text-[1.25rem] w-8 h-8 flex items-center justify-center">
              {state.valueNow}
            </div>
            <div className="absolute -left-2 -top-1.5 w-4 h-4 rounded-full bg-white border-[#242424] border"></div>
          </div>
        )}
        trackClassName="h-1 bg-range-gradient slider-track"
        min={min}
        max={max}
        defaultValue={defaultValue}
      />
    </div>
  );
};
