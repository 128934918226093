/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useMemo, useRef } from "react";
import {
  MeshTransmissionMaterial,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Group, Mesh, MeshPhysicalMaterial, Vector3 } from "three";
import { vertexShader, fragmentShader } from "../bush-material";
import { useFrame } from "@react-three/fiber";
import { Point } from "app/math/point";
import { unnecessaryBushes } from "./unnecessary-bushes";
import { HoverMesh } from "./hover-mesh";
import { HoverGroup } from "./hover-group";

type GLTFResult = GLTF & {
  nodes: {
    PLITA_PEREKR_TIA_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE: THREE.Mesh;
    INTER_ER___MEBEL__DLA_VODOSTOKOV: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002: THREE.Mesh;
    Cube: THREE.Mesh;
    Object001: THREE.Mesh;
    Box251: THREE.Mesh;
    Line002: THREE.Mesh;
    Plane001: THREE.Mesh;
    Box251001: THREE.Mesh;
    Object012: THREE.Mesh;
    Object013: THREE.Mesh;
    Object014: THREE.Mesh;
    Object015: THREE.Mesh;
    Object016: THREE.Mesh;
    Object017: THREE.Mesh;
    Object014001: THREE.Mesh;
    Object017001: THREE.Mesh;
    Object014003: THREE.Mesh;
    Object017003: THREE.Mesh;
    Object014004: THREE.Mesh;
    Object017004: THREE.Mesh;
    Object014007: THREE.Mesh;
    Object017007: THREE.Mesh;
    Object014008: THREE.Mesh;
    Object017008: THREE.Mesh;
    Object014009: THREE.Mesh;
    Object017009: THREE.Mesh;
    Object014011: THREE.Mesh;
    Object017011: THREE.Mesh;
    Object014012: THREE.Mesh;
    Object017012: THREE.Mesh;
    Object014013: THREE.Mesh;
    Object017013: THREE.Mesh;
    Object012002: THREE.Mesh;
    Object015002: THREE.Mesh;
    Object012003: THREE.Mesh;
    Object015003: THREE.Mesh;
    Object012004: THREE.Mesh;
    Object015004: THREE.Mesh;
    Object012005: THREE.Mesh;
    Object015005: THREE.Mesh;
    Object012006: THREE.Mesh;
    Object015006: THREE.Mesh;
    Object012007: THREE.Mesh;
    Object015007: THREE.Mesh;
    Object012008: THREE.Mesh;
    Object015008: THREE.Mesh;
    Object012009: THREE.Mesh;
    Object015009: THREE.Mesh;
    Object012010: THREE.Mesh;
    Object015010: THREE.Mesh;
    Object012011: THREE.Mesh;
    Object015011: THREE.Mesh;
    Object012012: THREE.Mesh;
    Object015012: THREE.Mesh;
    Object012013: THREE.Mesh;
    Object015013: THREE.Mesh;
    Object012014: THREE.Mesh;
    Object015014: THREE.Mesh;
    Object012015: THREE.Mesh;
    Object015015: THREE.Mesh;
    Object012016: THREE.Mesh;
    Object015016: THREE.Mesh;
    Object012017: THREE.Mesh;
    Object015017: THREE.Mesh;
    Object012018: THREE.Mesh;
    Object015018: THREE.Mesh;
    Object012019: THREE.Mesh;
    Object015019: THREE.Mesh;
    Object013002: THREE.Mesh;
    Object016002: THREE.Mesh;
    Object013003: THREE.Mesh;
    Object016003: THREE.Mesh;
    Object013004: THREE.Mesh;
    Object016004: THREE.Mesh;
    Object013005: THREE.Mesh;
    Object016005: THREE.Mesh;
    Object013006: THREE.Mesh;
    Object016006: THREE.Mesh;
    Object013007: THREE.Mesh;
    Object016007: THREE.Mesh;
    Object013008: THREE.Mesh;
    Object016008: THREE.Mesh;
    Object013009: THREE.Mesh;
    Object016009: THREE.Mesh;
    Object013010: THREE.Mesh;
    Object016010: THREE.Mesh;
    Object013011: THREE.Mesh;
    Object016011: THREE.Mesh;
    Object013012: THREE.Mesh;
    Object016012: THREE.Mesh;
    Object013013: THREE.Mesh;
    Object016013: THREE.Mesh;
    Object013014: THREE.Mesh;
    Object016014: THREE.Mesh;
    Object013015: THREE.Mesh;
    Object016015: THREE.Mesh;
    Object013016: THREE.Mesh;
    Object016016: THREE.Mesh;
    Object013017: THREE.Mesh;
    Object016017: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144001: THREE.Mesh;
    Object011001: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144002: THREE.Mesh;
    Object011002: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144004: THREE.Mesh;
    Object011004: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144005: THREE.Mesh;
    Object011005: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144008: THREE.Mesh;
    Object011008: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144009: THREE.Mesh;
    Object011009: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144010: THREE.Mesh;
    Object011010: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144011: THREE.Mesh;
    Object011011: THREE.Mesh;
    Object013018: THREE.Mesh;
    Object016018: THREE.Mesh;
    Object013019: THREE.Mesh;
    Object016019: THREE.Mesh;
    Object012020: THREE.Mesh;
    Object015020: THREE.Mesh;
    Object013020: THREE.Mesh;
    Object016020: THREE.Mesh;
    Object013021: THREE.Mesh;
    Object016021: THREE.Mesh;
    Object012021: THREE.Mesh;
    Object015021: THREE.Mesh;
    Object013022: THREE.Mesh;
    Object016022: THREE.Mesh;
    Object013023: THREE.Mesh;
    Object016023: THREE.Mesh;
    Object012022: THREE.Mesh;
    Object015022: THREE.Mesh;
    Object013024: THREE.Mesh;
    Object016024: THREE.Mesh;
    Object013025: THREE.Mesh;
    Object016025: THREE.Mesh;
    Object012023: THREE.Mesh;
    Object015023: THREE.Mesh;
    Object013026: THREE.Mesh;
    Object016026: THREE.Mesh;
    Object013027: THREE.Mesh;
    Object016027: THREE.Mesh;
    Object012024: THREE.Mesh;
    Object015024: THREE.Mesh;
    Object013028: THREE.Mesh;
    Object016028: THREE.Mesh;
    Object013029: THREE.Mesh;
    Object016029: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144026: THREE.Mesh;
    Object011026: THREE.Mesh;
    Object013033: THREE.Mesh;
    Object016033: THREE.Mesh;
    Object013034: THREE.Mesh;
    Object016034: THREE.Mesh;
    Object014025: THREE.Mesh;
    Object017026: THREE.Mesh;
    Object013035: THREE.Mesh;
    Object016035: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144027: THREE.Mesh;
    Object011027: THREE.Mesh;
    Object014026: THREE.Mesh;
    Object017027: THREE.Mesh;
    Object014027: THREE.Mesh;
    Object017028: THREE.Mesh;
    Object013036: THREE.Mesh;
    Object016036: THREE.Mesh;
    Object014028: THREE.Mesh;
    Object017029: THREE.Mesh;
    Object013038: THREE.Mesh;
    Object016038: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144029: THREE.Mesh;
    Object011029: THREE.Mesh;
    Object014029: THREE.Mesh;
    Object017030: THREE.Mesh;
    Object014030: THREE.Mesh;
    Object017031: THREE.Mesh;
    Object013001: THREE.Mesh;
    Object016001: THREE.Mesh;
    Object013030: THREE.Mesh;
    Object016030: THREE.Mesh;
    Object012001: THREE.Mesh;
    Object013031: THREE.Mesh;
    Object016031: THREE.Mesh;
    Object012026: THREE.Mesh;
    Object015001: THREE.Mesh;
    Object013032: THREE.Mesh;
    Object016032: THREE.Mesh;
    Object012027: THREE.Mesh;
    Object015026: THREE.Mesh;
    Object012028: THREE.Mesh;
    Object015027: THREE.Mesh;
    Object013037: THREE.Mesh;
    Object016037: THREE.Mesh;
    Object013039: THREE.Mesh;
    Object016039: THREE.Mesh;
    Object013040: THREE.Mesh;
    Object016040: THREE.Mesh;
    Object012029: THREE.Mesh;
    Object015028: THREE.Mesh;
    Object012030: THREE.Mesh;
    Object015029: THREE.Mesh;
    Object012031: THREE.Mesh;
    Object015030: THREE.Mesh;
    Object012032: THREE.Mesh;
    Object015031: THREE.Mesh;
    Object012033: THREE.Mesh;
    Object015032: THREE.Mesh;
    Object012034: THREE.Mesh;
    Object015033: THREE.Mesh;
    Object012035: THREE.Mesh;
    Object015034: THREE.Mesh;
    Object012036: THREE.Mesh;
    Object015035: THREE.Mesh;
    Object012037: THREE.Mesh;
    Object015036: THREE.Mesh;
    Object013041: THREE.Mesh;
    Object016041: THREE.Mesh;
    Object013042: THREE.Mesh;
    Object016042: THREE.Mesh;
    Object013043: THREE.Mesh;
    Object016043: THREE.Mesh;
    Object013044: THREE.Mesh;
    Object016044: THREE.Mesh;
  };
  materials: {
    ["Material.002"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["Material.003"]: THREE.MeshStandardMaterial;
    Material: THREE.MeshStandardMaterial;
  };
};

useTexture.preload("perlin.png");

export function House1(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/house1/House 1 everything but grass.glb"
  ) as GLTFResult;
  const perlin = useTexture("perlin.png");

  const windowMaterial = useMemo(() => {
    const m = materials["Material.001"].clone(); //new THREE.MeshStandardMaterial({ roughness: 0.95 });
    m.roughness = 0;
    m.metalness = 0.4; //0.215;
    return m;
  }, []);

  const bushesMaterial = useMemo(() => {
    const m = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader,
      uniforms: {
        map: { value: materials.Material.map },
        perlin: { value: perlin },
        time: { value: 0 },
      },
    });
    return m;
  }, []);
  const timeRef = useRef(0);
  useFrame((three, delta) => {
    timeRef.current += delta;
    bushesMaterial.uniforms.time.value = timeRef.current;
    bushesMaterial.uniformsNeedUpdate = true;
  });
  const ref = useRef<Group>(null);
  useEffect(() => {
    // console.log(materials);
    for (let mat of Object.keys(materials)) {
      //@ts-ignore
      //console.log(materials[mat], mat);
    }
    materials["Material.003"].roughness = 1;
    materials["Material.003"].metalness = 1;
    materials["Material.003"].needsUpdate = true;

    if (ref.current) {
      for (let obj of ref.current.children) {
        const { x, y, z } = obj.position;
        if (
          unnecessaryBushes.find(f => f[0] === x && f[1] === y && f[2] === z)
        ) {
          obj.visible = false;
        }
      }
    }
  }, []);
  const objects = useRef<Point[]>([]);
  return (
    <HoverGroup>
      <group
        {...props}
        dispose={null}
        onPointerDown={e => {
          // if ((e.object as Mesh).material !== bushesMaterial) return;
          // e.stopPropagation();
          //console.log((e.object as unknown as Mesh).position);
          // objects.current.push([
          // e.object.position.x,
          // e.object.position.y,
          // e.object.position.z,
          // ]);
          // e.object.visible = false;
          // console.log(objects.current);
        }}
        ref={ref}
      >
        <HoverMesh
          code={2}
          castShadow
          // receiveShadow
          geometry={nodes.PLITA_PEREKR_TIA_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          code={3}
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.002"]}
          position={[3.886, 0.297, 10.597]}
        />
        <HoverMesh
          code={3}
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA.geometry
          }
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          code={1}
          castShadow
          // receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          code={3}
          castShadow
          // receiveShadow
          receiveShadow={false}
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          castShadow
          code={6}
          // receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          code={1}
          castShadow
          // receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          castShadow
          // column
          // receiveShadow
          code={3}
          receiveShadow={false}
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          castShadow
          code={1}
          // receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART.geometry
          }
          material={materials["Material.001"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          position={[3.886, 0.296, 10.597]}
        >
          {/*<MeshTransmissionMaterial
          samples={32}
          resolution={512}
          anisotropicBlur={10}
          thickness={0.01}
          roughness={0.85}
          metalness={0.15}
          toneMapped={false}
          distortionScale={0}
          temporalDistortion={0}
          transparent
          opacity={0.5}
      />*/}
          <meshPhysicalMaterial
            transparent
            opacity={0.35}
            roughness={0}
            metalness={0.5}
            color="black"
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          position={[3.886, 0.296, 10.597]}
        >
          <MeshTransmissionMaterial
            samples={8}
            resolution={512}
            anisotropicBlur={1}
            thickness={0.1}
            roughness={1}
            metalness={0.15}
            toneMapped={false}
            distortionScale={0}
            temporalDistortion={0}
            transparent
            opacity={0.35}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_VODOSTOKOV.geometry}
          material={materials["Material.001"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          castShadow
          // receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001
              .geometry
          }
          material={materials["Material.001"]}
          position={[3.886, 0.296, 10.597]}
        />
        <HoverMesh
          receiveShadow={false}
          code={3}
          castShadow
          // receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002.geometry
          }
          material={windowMaterial}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002
              .geometry
          }
          material={materials["Material.001"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials["Material.001"]}
          position={[2.471, 0.13, 15.479]}
          scale={[0.04, 0.25, 0.04]}
        />
        <mesh
          castShadow
          // receiveShadow
          geometry={nodes.Object001.geometry}
          material={materials["Material.003"]}
          position={[9.986, 0.054, 5.878]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box251.geometry}
          material={materials["Material.003"]}
          position={[39.322, 0.264, -4.663]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Line002.geometry}
          material={materials["Material.003"]}
          position={[12.712, 0.026, 7.451]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials["Material.003"]}
          position={[8.583, 0.014, 5.093]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Box251001.geometry}
          material={materials["Material.003"]}
          position={[39.322, 0.264, -4.663]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012.geometry}
          material={bushesMaterial}
          position={[25.206, -0.039, -15.922]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013.geometry}
          material={bushesMaterial}
          position={[24.162, -0.039, -15.987]}
          scale={1.451}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014.geometry}
          material={bushesMaterial}
          position={[21.417, -0.039, -17.906]}
          rotation={[0, -0.102, 0]}
          scale={1.05}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015.geometry}
          material={bushesMaterial}
          position={[29.88, -0.039, -15.922]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016.geometry}
          material={bushesMaterial}
          position={[30.159, -0.039, -15.987]}
          scale={1.451}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017.geometry}
          material={bushesMaterial}
          position={[25.735, -0.039, -17.465]}
          rotation={[0, -0.102, 0]}
          scale={1.05}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014001.geometry}
          material={bushesMaterial}
          position={[31.824, -0.036, -2.475]}
          rotation={[0, 0.444, 0]}
          scale={1.458}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017001.geometry}
          material={bushesMaterial}
          position={[37.267, -0.036, -5.066]}
          rotation={[0, 0.444, 0]}
          scale={1.458}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014003.geometry}
          material={bushesMaterial}
          position={[40.893, -0.039, 10.327]}
          rotation={[-Math.PI, 0.751, -Math.PI]}
          scale={1.05}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017003.geometry}
          material={bushesMaterial}
          position={[37.719, -0.039, 7.366]}
          rotation={[-Math.PI, 0.751, -Math.PI]}
          scale={1.05}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014004.geometry}
          material={bushesMaterial}
          position={[38.241, -0.023, 27.523]}
          rotation={[-Math.PI, 0.751, -Math.PI]}
          scale={1.603}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017004.geometry}
          material={bushesMaterial}
          position={[33.395, -0.023, 23.003]}
          rotation={[-Math.PI, 0.751, -Math.PI]}
          scale={1.603}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014007.geometry}
          material={bushesMaterial}
          position={[-18.146, -0.077, 22.864]}
          rotation={[0, -1.349, 0]}
          scale={1.589}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017007.geometry}
          material={bushesMaterial}
          position={[-16.698, -0.077, 29.27]}
          rotation={[0, -1.349, 0]}
          scale={1.589}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014008.geometry}
          material={bushesMaterial}
          position={[-20.527, -0.039, 6.856]}
          rotation={[0, -1.349, 0]}
          scale={0.944}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017008.geometry}
          material={bushesMaterial}
          position={[-19.666, -0.039, 10.663]}
          rotation={[0, -1.349, 0]}
          scale={0.944}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014009.geometry}
          material={bushesMaterial}
          position={[-14.285, -0.039, -7.928]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.011}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017009.geometry}
          material={bushesMaterial}
          position={[-17.789, -0.039, -5.652]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.011}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014011.geometry}
          material={bushesMaterial}
          position={[-11.731, -0.039, -21.643]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.062}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017011.geometry}
          material={bushesMaterial}
          position={[-15.414, -0.039, -19.252]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.062}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014012.geometry}
          material={bushesMaterial}
          position={[9.321, -0.039, -24.906]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.062}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017012.geometry}
          material={bushesMaterial}
          position={[5.638, -0.039, -22.514]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.062}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014013.geometry}
          material={bushesMaterial}
          position={[40.59, -0.072, -21.979]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.549}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017013.geometry}
          material={bushesMaterial}
          position={[35.221, -0.072, -18.493]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={1.549}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012002.geometry}
          material={bushesMaterial}
          position={[33.935, -0.039, -17.039]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015002.geometry}
          material={bushesMaterial}
          position={[38.609, -0.039, -17.039]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012003.geometry}
          material={bushesMaterial}
          position={[20.192, -0.039, -21.6]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015003.geometry}
          material={bushesMaterial}
          position={[24.866, -0.039, -21.6]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012004.geometry}
          material={bushesMaterial}
          position={[3.791, -0.039, -22.325]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015004.geometry}
          material={bushesMaterial}
          position={[8.465, -0.039, -22.325]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012005.geometry}
          material={bushesMaterial}
          position={[-2.625, -0.039, -19.716]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015005.geometry}
          material={bushesMaterial}
          position={[2.049, -0.039, -19.716]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012006.geometry}
          material={bushesMaterial}
          position={[-13.644, -0.039, -19.462]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015006.geometry}
          material={bushesMaterial}
          position={[-8.969, -0.039, -19.462]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012007.geometry}
          material={bushesMaterial}
          position={[-19.624, -0.039, -6.305]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015007.geometry}
          material={bushesMaterial}
          position={[-14.95, -0.039, -6.305]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012008.geometry}
          material={bushesMaterial}
          position={[-4.256, -0.039, -4.964]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015008.geometry}
          material={bushesMaterial}
          position={[0.418, -0.039, -4.964]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012009.geometry}
          material={bushesMaterial}
          position={[-7.087, -0.039, 1.536]}
          rotation={[0, 0.617, 0]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015009.geometry}
          material={bushesMaterial}
          position={[-3.275, -0.039, -1.169]}
          rotation={[0, 0.617, 0]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012010.geometry}
          material={bushesMaterial}
          position={[-18.202, -0.039, 10.87]}
          rotation={[0, 1.284, 0]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015010.geometry}
          material={bushesMaterial}
          position={[-16.88, -0.039, 6.387]}
          rotation={[0, 1.284, 0]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012011.geometry}
          material={bushesMaterial}
          position={[-8.505, -0.039, 5.61]}
          rotation={[0, 1.284, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015011.geometry}
          material={bushesMaterial}
          position={[-7.294, -0.039, 1.498]}
          rotation={[0, 1.284, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012012.geometry}
          material={bushesMaterial}
          position={[-3.658, -0.039, 15.914]}
          rotation={[0, 1.284, 0]}
          scale={1.04}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015012.geometry}
          material={bushesMaterial}
          position={[-2.398, -0.039, 11.637]}
          rotation={[0, 1.284, 0]}
          scale={1.04}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012013.geometry}
          material={bushesMaterial}
          position={[-17.523, -0.039, 27.877]}
          rotation={[0, 0.862, 0]}
          scale={1.115}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015013.geometry}
          material={bushesMaterial}
          position={[-14.412, -0.039, 24.249]}
          rotation={[0, 0.862, 0]}
          scale={1.115}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012014.geometry}
          material={bushesMaterial}
          position={[5.13, -0.039, 20.374]}
          rotation={[0, 0.862, 0]}
          scale={1.115}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015014.geometry}
          material={bushesMaterial}
          position={[8.241, -0.039, 16.746]}
          rotation={[0, 0.862, 0]}
          scale={1.115}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012015.geometry}
          material={bushesMaterial}
          position={[31.383, -0.039, 23.544]}
          rotation={[0, 0.862, 0]}
          scale={1.263}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015015.geometry}
          material={bushesMaterial}
          position={[34.907, -0.039, 19.435]}
          rotation={[0, 0.862, 0]}
          scale={1.263}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012016.geometry}
          material={bushesMaterial}
          position={[35.728, -0.039, 7.336]}
          rotation={[0, 0.862, 0]}
          scale={1.058}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015016.geometry}
          material={bushesMaterial}
          position={[38.681, -0.039, 3.892]}
          rotation={[0, 0.862, 0]}
          scale={1.058}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012017.geometry}
          material={bushesMaterial}
          position={[35.728, -0.039, 7.336]}
          rotation={[0, 0.862, 0]}
          scale={1.058}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015017.geometry}
          material={bushesMaterial}
          position={[38.681, -0.039, 3.892]}
          rotation={[0, 0.862, 0]}
          scale={1.058}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012018.geometry}
          material={bushesMaterial}
          position={[23.849, -0.039, 4.725]}
          rotation={[0, -1.477, 0]}
          scale={0.994}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015018.geometry}
          material={bushesMaterial}
          position={[24.246, -0.039, 8.968]}
          rotation={[0, -1.477, 0]}
          scale={0.994}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012019.geometry}
          material={bushesMaterial}
          position={[19.263, -0.039, -22.759]}
          rotation={[0, -1.477, 0]}
          scale={1.224}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015019.geometry}
          material={bushesMaterial}
          position={[19.753, -0.039, -17.533]}
          rotation={[0, -1.477, 0]}
          scale={1.224}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013002.geometry}
          material={bushesMaterial}
          position={[33.401, -0.039, -6.352]}
          scale={1.233}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016002.geometry}
          material={bushesMaterial}
          position={[38.498, -0.039, -6.352]}
          scale={1.233}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013003.geometry}
          material={bushesMaterial}
          position={[22.209, -0.039, 5.972]}
          scale={1.168}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016003.geometry}
          material={bushesMaterial}
          position={[27.037, -0.039, 5.972]}
          scale={1.168}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013004.geometry}
          material={bushesMaterial}
          position={[34.531, -0.039, 6.062]}
          scale={1.052}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016004.geometry}
          material={bushesMaterial}
          position={[38.879, -0.039, 6.062]}
          scale={1.052}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013005.geometry}
          material={bushesMaterial}
          position={[29.493, -0.039, 22.047]}
          scale={1.052}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016005.geometry}
          material={bushesMaterial}
          position={[33.84, -0.039, 22.047]}
          scale={1.052}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013006.geometry}
          material={bushesMaterial}
          position={[2.418, -0.039, 21.865]}
          scale={1.052}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016006.geometry}
          material={bushesMaterial}
          position={[6.765, -0.039, 21.865]}
          scale={1.052}
        />
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object013007.geometry}
        material={bushesMaterial}
        position={[-5.705, 0.004, 15.195]}
        scale={1.596}
      /> */}
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object016007.geometry}
        material={bushesMaterial}
        position={[0.893, 0.004, 15.195]}
        scale={1.596}
      /> */}
        {/*<mesh
        castShadow
        receiveShadow
        geometry={nodes.Object013008.geometry}
        material={bushesMaterial}
        position={[-18.461, -0.039, 26.614]}
        scale={0.912}
    />*/}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016008.geometry}
          material={bushesMaterial}
          position={[-14.692, -0.039, 26.614]}
          scale={0.912}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013009.geometry}
          material={bushesMaterial}
          position={[-9.434, -0.039, 4.91]}
          scale={0.985}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016009.geometry}
          material={bushesMaterial}
          position={[-5.364, -0.039, 4.91]}
          scale={0.985}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013010.geometry}
          material={bushesMaterial}
          position={[-7.868, -0.039, 0.923]}
          scale={1.045}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016010.geometry}
          material={bushesMaterial}
          position={[-3.547, -0.039, 0.923]}
          scale={1.045}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013011.geometry}
          material={bushesMaterial}
          position={[-20.614, -0.039, -7.233]}
          scale={1.045}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016011.geometry}
          material={bushesMaterial}
          position={[-16.294, -0.039, -7.233]}
          scale={1.045}
        />
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object013012.geometry}
        material={bushesMaterial}
        position={[-5.999, -0.002, -5.848]}
        scale={1.4}
      /> */}
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object016012.geometry}
        material={bushesMaterial}
        position={[-0.211, -0.002, -5.848]}
        scale={1.4}
      /> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013013.geometry}
          material={bushesMaterial}
          position={[-8.175, -0.075, -21.169]}
          scale={2.049}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016013.geometry}
          material={bushesMaterial}
          position={[0.294, -0.075, -21.169]}
          scale={2.049}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013014.geometry}
          material={bushesMaterial}
          position={[-16.598, -0.039, -18.137]}
          scale={1.177}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016014.geometry}
          material={bushesMaterial}
          position={[-11.731, -0.039, -18.137]}
          scale={1.177}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013015.geometry}
          material={bushesMaterial}
          position={[2.742, -0.039, -21.066]}
          scale={1.041}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016015.geometry}
          material={bushesMaterial}
          position={[7.047, -0.039, -21.066]}
          scale={1.041}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013016.geometry}
          material={bushesMaterial}
          position={[16.887, -0.039, -21.217]}
          scale={0.993}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016016.geometry}
          material={bushesMaterial}
          position={[20.992, -0.039, -21.217]}
          scale={0.993}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013017.geometry}
          material={bushesMaterial}
          position={[16.887, -0.039, -21.217]}
          scale={0.993}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016017.geometry}
          material={bushesMaterial}
          position={[20.992, -0.039, -21.217]}
          scale={0.993}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144001.geometry}
          material={bushesMaterial}
          position={[31.183, -0.039, -3.858]}
          scale={0.68}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011001.geometry}
          material={bushesMaterial}
          position={[33.995, -0.039, -3.858]}
          scale={0.68}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144002.geometry}
          material={bushesMaterial}
          position={[34.886, -0.039, 12.988]}
          rotation={[0, 1.221, 0]}
          scale={0.68}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011002.geometry}
          material={bushesMaterial}
          position={[35.85, -0.039, 10.347]}
          rotation={[0, 1.221, 0]}
          scale={0.68}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144004.geometry}
          material={bushesMaterial}
          position={[-21, -0.039, 28.228]}
          rotation={[0, 0.142, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011004.geometry}
          material={bushesMaterial}
          position={[-17.946, -0.039, 27.792]}
          rotation={[0, 0.142, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144005.geometry}
          material={bushesMaterial}
          position={[-24.044, -0.039, 10.047]}
          rotation={[0, 0.142, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011005.geometry}
          material={bushesMaterial}
          position={[-20.991, -0.039, 9.611]}
          rotation={[0, 0.142, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144008.geometry}
          material={bushesMaterial}
          position={[-28.203, -0.106, 0.059]}
          rotation={[0, 0.524, 0]}
          scale={1.313}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011008.geometry}
          material={bushesMaterial}
          position={[-23.502, -0.106, -2.657]}
          rotation={[0, 0.524, 0]}
          scale={1.313}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144009.geometry}
          material={bushesMaterial}
          position={[-20.496, -0.039, -15.03]}
          rotation={[0, 0.524, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011009.geometry}
          material={bushesMaterial}
          position={[-17.825, -0.039, -16.573]}
          rotation={[0, 0.524, 0]}
          scale={0.746}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144010.geometry}
          material={bushesMaterial}
          position={[-9.921, -0.039, -16.22]}
          rotation={[0, 0.524, 0]}
          scale={0.885}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011010.geometry}
          material={bushesMaterial}
          position={[-6.753, -0.039, -18.051]}
          rotation={[0, 0.524, 0]}
          scale={0.885}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144011.geometry}
          material={bushesMaterial}
          position={[12.078, -0.039, -19.115]}
          rotation={[0, 0.524, 0]}
          scale={0.885}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011011.geometry}
          material={bushesMaterial}
          position={[15.246, -0.039, -20.945]}
          rotation={[0, 0.524, 0]}
          scale={0.885}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013018.geometry}
          material={bushesMaterial}
          position={[14.248, -0.039, -8.875]}
          scale={1.02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016018.geometry}
          material={bushesMaterial}
          position={[18.466, -0.039, -8.875]}
          scale={1.02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013019.geometry}
          material={bushesMaterial}
          position={[9.129, -0.039, -7.385]}
          rotation={[0, 0.871, 0]}
          scale={1.015}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016019.geometry}
          material={bushesMaterial}
          position={[11.831, -0.039, -10.597]}
          rotation={[0, 0.871, 0]}
          scale={1.015}
        />
        <mesh
          castShadow
          // receiveShadow
          geometry={nodes.Object012020.geometry}
          material={bushesMaterial}
          position={[5.918, -0.039, -9.004]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015020.geometry}
          material={bushesMaterial}
          position={[11.185, -0.039, -9.004]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013020.geometry}
          material={bushesMaterial}
          position={[18.471, -0.039, 2.602]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016020.geometry}
          material={bushesMaterial}
          position={[18.471, -0.039, 7.141]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013021.geometry}
          material={bushesMaterial}
          position={[16.208, -0.004, -2.722]}
          rotation={[0, -0.7, 0]}
          scale={1.417}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016021.geometry}
          material={bushesMaterial}
          position={[20.69, -0.004, 1.049]}
          rotation={[0, -0.7, 0]}
          scale={1.417}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012021.geometry}
          material={bushesMaterial}
          position={[18.6, -0.039, -3.596]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015021.geometry}
          material={bushesMaterial}
          position={[18.6, -0.039, 1.671]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013022.geometry}
          material={bushesMaterial}
          position={[18.558, -0.039, 19.043]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016022.geometry}
          material={bushesMaterial}
          position={[18.558, -0.039, 23.582]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013023.geometry}
          material={bushesMaterial}
          position={[16.938, -0.039, 14.068]}
          rotation={[0, -0.7, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016023.geometry}
          material={bushesMaterial}
          position={[20.411, -0.039, 16.991]}
          rotation={[0, -0.7, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012022.geometry}
          material={bushesMaterial}
          position={[18.687, -0.039, 12.846]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015022.geometry}
          material={bushesMaterial}
          position={[18.687, -0.039, 18.112]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013024.geometry}
          material={bushesMaterial}
          position={[10.207, -0.039, 27.481]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016024.geometry}
          material={bushesMaterial}
          position={[10.207, -0.039, 32.02]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013025.geometry}
          material={bushesMaterial}
          position={[8.291, -0.021, 22.079]}
          rotation={[0, -0.7, 0]}
          scale={1.379}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016025.geometry}
          material={bushesMaterial}
          position={[12.654, -0.021, 25.751]}
          rotation={[0, -0.7, 0]}
          scale={1.379}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012023.geometry}
          material={bushesMaterial}
          position={[10.336, -0.039, 21.284]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015023.geometry}
          material={bushesMaterial}
          position={[10.336, -0.039, 26.55]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013026.geometry}
          material={bushesMaterial}
          position={[1.16, -0.039, 8.169]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016026.geometry}
          material={bushesMaterial}
          position={[1.16, -0.039, 12.709]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.098}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013027.geometry}
          material={bushesMaterial}
          position={[-0.874, -0.012, 2.587]}
          rotation={[0, -0.7, 0]}
          scale={1.477}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016027.geometry}
          material={bushesMaterial}
          position={[3.799, -0.012, 6.519]}
          rotation={[0, -0.7, 0]}
          scale={1.477}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012024.geometry}
          material={bushesMaterial}
          position={[1.289, -0.039, 1.972]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015024.geometry}
          material={bushesMaterial}
          position={[1.289, -0.039, 7.239]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013028.geometry}
          material={bushesMaterial}
          position={[15.831, -0.009, 26.687]}
          scale={1.473}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016028.geometry}
          material={bushesMaterial}
          position={[21.921, -0.009, 26.687]}
          scale={1.473}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013029.geometry}
          material={bushesMaterial}
          position={[-1.473, -0.003, -3.501]}
          scale={1.401}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016029.geometry}
          material={bushesMaterial}
          position={[4.321, -0.003, -3.501]}
          scale={1.401}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144026.geometry}
          material={bushesMaterial}
          position={[11.005, -0.039, 7.47]}
          rotation={[0, 0.939, 0]}
          scale={0.533}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011026.geometry}
          material={bushesMaterial}
          position={[12.305, -0.039, 5.693]}
          rotation={[0, 0.939, 0]}
          scale={0.533}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013033.geometry}
          material={bushesMaterial}
          position={[13.228, -0.039, 3.986]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016033.geometry}
          material={bushesMaterial}
          position={[13.228, -0.039, 7.028]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013034.geometry}
          material={bushesMaterial}
          position={[12.025, -0.039, 2.351]}
          rotation={[0, -0.7, 0]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016034.geometry}
          material={bushesMaterial}
          position={[14.352, -0.039, 4.31]}
          rotation={[0, -0.7, 0]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014025.geometry}
          material={bushesMaterial}
          position={[14.764, -0.039, 9.026]}
          rotation={[-Math.PI, 0.995, -Math.PI]}
          scale={0.685}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017026.geometry}
          material={bushesMaterial}
          position={[13.222, -0.039, 6.651]}
          rotation={[-Math.PI, 0.995, -Math.PI]}
          scale={0.685}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013035.geometry}
          material={bushesMaterial}
          position={[11.544, -0.039, 7.882]}
          scale={0.865}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016035.geometry}
          material={bushesMaterial}
          position={[15.119, -0.039, 7.882]}
          scale={0.865}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144027.geometry}
          material={bushesMaterial}
          position={[8.728, -0.039, 10.21]}
          scale={0.648}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011027.geometry}
          material={bushesMaterial}
          position={[11.406, -0.039, 10.21]}
          scale={0.648}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014026.geometry}
          material={bushesMaterial}
          position={[15.505, -0.039, 7.65]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017027.geometry}
          material={bushesMaterial}
          position={[13.331, -0.039, 9.062]}
          rotation={[-Math.PI, -0.576, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014027.geometry}
          material={bushesMaterial}
          position={[13.624, -0.039, 14.176]}
          rotation={[-Math.PI, 1.421, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017028.geometry}
          material={bushesMaterial}
          position={[13.237, -0.039, 11.613]}
          rotation={[-Math.PI, 1.421, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013036.geometry}
          material={bushesMaterial}
          position={[14.963, -0.039, -4.704]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016036.geometry}
          material={bushesMaterial}
          position={[11.922, -0.039, -4.704]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={0.736}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014028.geometry}
          material={bushesMaterial}
          position={[10.055, -0.039, -3.167]}
          rotation={[0, 0.576, 0]}
          scale={0.685}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017029.geometry}
          material={bushesMaterial}
          position={[12.462, -0.039, -4.71]}
          rotation={[0, 0.576, 0]}
          scale={0.685}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013038.geometry}
          material={bushesMaterial}
          position={[11.231, -0.039, -6.388]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.865}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016038.geometry}
          material={bushesMaterial}
          position={[11.231, -0.039, -2.812]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.865}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144029.geometry}
          material={bushesMaterial}
          position={[8.903, -0.039, -9.203]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.648}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object011029.geometry}
          material={bushesMaterial}
          position={[8.903, -0.039, -6.526]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0.648}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014029.geometry}
          material={bushesMaterial}
          position={[11.463, -0.039, -2.427]}
          rotation={[-Math.PI, 0.995, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017030.geometry}
          material={bushesMaterial}
          position={[10.051, -0.039, -4.6]}
          rotation={[-Math.PI, 0.995, -Math.PI]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object014030.geometry}
          material={bushesMaterial}
          position={[5.021, -0.039, -4.307]}
          rotation={[0, 0.15, 0]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object017031.geometry}
          material={bushesMaterial}
          position={[7.584, -0.039, -4.695]}
          rotation={[0, 0.15, 0]}
          scale={0.627}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013001.geometry}
          material={bushesMaterial}
          position={[22.159, 0.013, 4.644]}
          scale={1.54}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016001.geometry}
          material={bushesMaterial}
          position={[28.528, 0.013, 4.644]}
          scale={1.54}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013030.geometry}
          material={bushesMaterial}
          position={[12.557, 0.013, -6.611]}
          rotation={[0, 0.871, 0]}
          scale={1.459}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016030.geometry}
          material={bushesMaterial}
          position={[16.442, 0.013, -11.227]}
          rotation={[0, 0.871, 0]}
          scale={1.459}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012001.geometry}
          material={bushesMaterial}
          position={[12.269, -0.039, -8.995]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013031.geometry}
          material={bushesMaterial}
          position={[6.153, -0.008, -6.37]}
          rotation={[0, 0.871, 0]}
          scale={1.599}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016031.geometry}
          material={bushesMaterial}
          position={[10.41, -0.008, -11.428]}
          rotation={[0, 0.871, 0]}
          scale={1.599}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012026.geometry}
          material={bushesMaterial}
          position={[18.6, -0.039, 1.942]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015001.geometry}
          material={bushesMaterial}
          position={[18.6, -0.039, 7.209]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013032.geometry}
          material={bushesMaterial}
          position={[18.303, 0, 4.256]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.563}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016032.geometry}
          material={bushesMaterial}
          position={[18.303, 0, 10.72]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={1.563}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012027.geometry}
          material={bushesMaterial}
          position={[18.664, -0.039, 10.239]}
          rotation={[0, 0.093, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015026.geometry}
          material={bushesMaterial}
          position={[23.908, -0.039, 9.751]}
          rotation={[0, 0.093, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012028.geometry}
          material={bushesMaterial}
          position={[3.754, -0.039, -8.891]}
          rotation={[Math.PI, -1.125, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015027.geometry}
          material={bushesMaterial}
          position={[1.483, -0.039, -4.139]}
          rotation={[Math.PI, -1.125, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013037.geometry}
          material={bushesMaterial}
          position={[-0.531, -0.039, -7.828]}
          rotation={[0, -0.78, 0]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016037.geometry}
          material={bushesMaterial}
          position={[3.155, -0.039, -4.179]}
          rotation={[0, -0.78, 0]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013039.geometry}
          material={bushesMaterial}
          position={[-1.499, -0.039, 8.222]}
          rotation={[0, 0.311, 0]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016039.geometry}
          material={bushesMaterial}
          position={[3.438, -0.039, 6.633]}
          rotation={[0, 0.311, 0]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013040.geometry}
          material={bushesMaterial}
          position={[0.858, 0.094, 15.066]}
          rotation={[0, 1.424, 0]}
          scale={1.154}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016040.geometry}
          material={bushesMaterial}
          position={[1.554, 0.094, 10.344]}
          rotation={[0, 1.424, 0]}
          scale={1.154}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012029.geometry}
          material={bushesMaterial}
          position={[1.284, -0.039, -0.836]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015028.geometry}
          material={bushesMaterial}
          position={[-3.585, -0.039, 1.174]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012030.geometry}
          material={bushesMaterial}
          position={[18.809, -0.039, -6.289]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015029.geometry}
          material={bushesMaterial}
          position={[13.941, -0.039, -4.279]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012031.geometry}
          material={bushesMaterial}
          position={[18.961, -0.028, 18.294]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.459}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015030.geometry}
          material={bushesMaterial}
          position={[13.385, -0.028, 20.597]}
          rotation={[Math.PI, -0.392, Math.PI]}
          scale={1.459}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012032.geometry}
          material={bushesMaterial}
          position={[18.874, -0.039, 23.728]}
          rotation={[Math.PI, -1.314, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015031.geometry}
          material={bushesMaterial}
          position={[17.538, -0.039, 28.822]}
          rotation={[Math.PI, -1.314, Math.PI]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012033.geometry}
          material={bushesMaterial}
          position={[18.84, -0.039, 29.366]}
          rotation={[0, -0.272, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015032.geometry}
          material={bushesMaterial}
          position={[23.913, -0.039, 30.782]}
          rotation={[0, -0.272, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012034.geometry}
          material={bushesMaterial}
          position={[10.088, -0.039, 26.869]}
          rotation={[0, -0.272, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015033.geometry}
          material={bushesMaterial}
          position={[15.161, -0.039, 28.285]}
          rotation={[0, -0.272, 0]}
          scale={1.274}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012035.geometry}
          material={bushesMaterial}
          position={[10.053, -0.003, 18.626]}
          rotation={[0, -0.272, 0]}
          scale={1.2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015034.geometry}
          material={bushesMaterial}
          position={[14.834, -0.003, 19.96]}
          rotation={[0, -0.272, 0]}
          scale={1.2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012036.geometry}
          material={bushesMaterial}
          position={[10.17, -0.003, 32.455]}
          rotation={[0, 0.786, 0]}
          scale={1.2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015035.geometry}
          material={bushesMaterial}
          position={[13.679, -0.003, 28.945]}
          rotation={[0, 0.786, 0]}
          scale={1.2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object012037.geometry}
          material={bushesMaterial}
          position={[18.695, -0.039, 32.337]}
          rotation={[0, 0.615, 0]}
          scale={1.131}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object015036.geometry}
          material={bushesMaterial}
          position={[22.514, -0.039, 29.642]}
          rotation={[0, 0.615, 0]}
          scale={1.131}
        />
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object013041.geometry}
        material={bushesMaterial}
        position={[-9.731, -0.005, 0.055]}
        scale={1.413}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object016041.geometry}
        material={bushesMaterial}
        position={[-3.89, -0.005, 0.055]}
        scale={1.413}
      /> */}
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object013042.geometry}
        material={bushesMaterial}
        position={[-10.094, -0.009, 6.278]}
        scale={1.456}
      /> */}
        {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object016042.geometry}
        material={bushesMaterial}
        position={[-4.075, -0.009, 6.278]}
        scale={1.456}
      /> */}
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013043.geometry}
          material={bushesMaterial}
          position={[-4.712, -0.039, 16.7]}
          scale={1.041}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016043.geometry}
          material={bushesMaterial}
          position={[-0.408, -0.039, 16.7]}
          scale={1.041}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object013044.geometry}
          material={bushesMaterial}
          position={[3.201, 0.001, 21.49]}
          scale={1.377}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object016044.geometry}
          material={bushesMaterial}
          position={[8.894, 0.001, 21.49]}
          scale={1.377}
        />
      </group>
    </HoverGroup>
  );
}

useGLTF.preload("./meshes/house1/House 1 everything but grass.glb");
