import { Center, Resize } from "@react-three/drei";
import { FC } from "react";

export const CenterResizeScale: FC<JSX.IntrinsicElements["group"]> = ({
  children,
  ...props
}) => (
  <group {...props}>
    <Resize>
      <Center>{children}</Center>
    </Resize>
  </group>
);
