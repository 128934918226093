import { FC } from "react";

interface Props {
  number: number;
  max?: number;
  active?: boolean;
  hideNumber?: boolean;
  fat?: boolean;
}

const r = 16;
const c = 2 * Math.PI * r;

export const NumberCircle: FC<Props> = ({
  number,
  max = 5,
  active = true,
  hideNumber = false,
  fat = false,
}) => {
  const n = active ? number : 0;
  return (
    <div className="w-8 h-8 relative inline-flex items-center justify-center shrink-0">
      <svg
        className="absolute top-0 left-0 w-8 h-8 overflow-visible"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/"
      >
        {!fat && (
          <circle
            cx="16"
            cy="16"
            r={r}
            fill="none"
            stroke="#242424"
            opacity="0.15"
            strokeWidth="3.5"
          />
        )}
        <circle
          cx="16"
          cy="16"
          r={r}
          fill="none"
          stroke="url(#gradient)"
          strokeWidth={fat ? "6" : "3.5"}
          strokeDasharray={c}
          transform="rotate(-90 16 16)"
          strokeDashoffset={(1 - n / max) * c}
          filter="drop-shadow(0px 0.5px 1px rgba(0, 0, 0, 0.60))"
        />
        <defs>
          <filter
            id="filter"
            x="0"
            y="0"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_307_635"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_307_635"
              result="shape"
            />
          </filter>
          <linearGradient
            id="gradient"
            x1="2"
            y1="2"
            x2="16"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00C2FF" />
            <stop offset="1" stop-color="#9747FF" />
          </linearGradient>
        </defs>
      </svg>
      {!hideNumber && (
        <div className="text-dark text-[1.25rem] font-display uppercase pb-[0.0625rem]">
          {number}
        </div>
      )}
    </div>
  );
};
