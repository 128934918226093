import { FC, HTMLAttributes } from "react";

interface Props {
  Icon?: FC<HTMLAttributes<SVGSVGElement>>;
  title?: string;
  onClick?: (e: any) => void;
}

export const InactiveHouseItem: FC<Props> = ({ Icon, title, onClick }) => (
  <div
    className="inactive-item w-full py-1 justify-start items-center gap-4 inline-flex cursor-pointer hover:opacity-75"
    onClick={onClick}
  >
    {!!Icon && <Icon className="w-6 h-6" />}
    <div className=" opacity-50 text-dark text-1rem font-display uppercase tracking-wider">
      {title}
    </div>
    <div className="empty-section w-2 h-2 bg-[#FF4B4B] rounded-full"></div>
  </div>
);
