/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useMemo, useRef } from "react";
import { MeshTransmissionMaterial, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { PI } from "app/math";
import { HoverMesh } from "./hover-mesh";
import { HoverGroup } from "./hover-group";

type GLTFResult = GLTF & {
  nodes: {
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_TRUB_: THREE.Mesh;
    OBOLOCKA___KROVLA_DLA_TRUB_: THREE.Mesh;
    INTER_ER___MEBEL__DLA_OKNA_FLAT_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV: THREE.Mesh;
    OBOLOCKA___KROVLA_KROVLA_1_SMOOTH_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_: THREE.Mesh;
    BezierCurve055: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART: THREE.Mesh;
  };
  materials: {
    ["Material.007"]: THREE.MeshStandardMaterial;
    ["Material.008"]: THREE.MeshStandardMaterial;
    ["Material.009"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["Material.002"]: THREE.MeshStandardMaterial;
  };
};

export function House2(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/house2.remove.vx.colors.glb"
  ) as GLTFResult;
  const windowMaterial = useMemo(() => {
    const m = materials["Material.002"].clone(); //new THREE.MeshStandardMaterial({ roughness: 0.95 });
    m.roughness = 0;
    m.metalness = 0.215;
    m.opacity = 0.5;
    m.transparent = true;
    return m;
  }, []);
  return (
    <HoverGroup {...props} position={[0.6, -1.0, 1.2]} dispose={null}>
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004"
        castShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        name="KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_TRUB_"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_TRUB_.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="OBOLOCKA___KROVLA_DLA_TRUB_"
        castShadow
        geometry={nodes.OBOLOCKA___KROVLA_DLA_TRUB_.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={3}
        name="INTER_ER___MEBEL__DLA_OKNA_FLAT_PART"
        castShadow
        geometry={nodes.INTER_ER___MEBEL__DLA_OKNA_FLAT_PART.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        name="KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J"
        castShadow
        geometry={
          nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J.geometry
        }
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={1}
        name="KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={1}
        name="OBOLOCKA___KROVLA_KROVLA_1_SMOOTH_PART"
        castShadow
        geometry={nodes.OBOLOCKA___KROVLA_KROVLA_1_SMOOTH_PART.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={6}
        name="KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        name="KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA"
        castShadow
        receiveShadow={false}
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={2}
        name="KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        receiveShadow={false}
        code={3}
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA"
        castShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        receiveShadow={false}
        code={6}
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA"
        castShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        code={2}
        name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA"
        castShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <HoverMesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_"
        castShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="BezierCurve055"
        castShadow
        geometry={nodes.BezierCurve055.geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        code={3}
        receiveShadow={false}
        name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE"
        castShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE.geometry}
        material={windowMaterial}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE"
        castShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE.geometry}
      >
        <MeshTransmissionMaterial
          samples={8}
          resolution={512}
          anisotropicBlur={1}
          thickness={0.1}
          roughness={1}
          metalness={0.15}
          toneMapped={false}
          distortionScale={0}
          temporalDistortion={0}
          transparent
          opacity={0.35}
        />
      </mesh>
      <mesh
        name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART.geometry}
        material={windowMaterial}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART"
        castShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART.geometry}
        position={[0, 1.348, 7.681]}
      >
        <MeshTransmissionMaterial
          samples={8}
          resolution={512}
          anisotropicBlur={1}
          thickness={0.1}
          roughness={1}
          metalness={0.15}
          toneMapped={false}
          distortionScale={0}
          temporalDistortion={0}
          transparent
          opacity={0.35}
        />
      </mesh>
    </HoverGroup>
  );
}

useGLTF.preload("./meshes/house2.remove.vx.colors.glb");
