import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o base00.tsx цоколь00.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['6']: THREE.Mesh
    ['7']: THREE.Mesh
    ['2']: THREE.Mesh
    ['4']: THREE.Mesh
    ['1']: THREE.Mesh
    ['3']: THREE.Mesh
    ['5']: THREE.Mesh
    ['8']: THREE.Mesh
    ground: THREE.Mesh
    base: THREE.Mesh
    cement: THREE.Mesh
    armature: THREE.Mesh
  }
  materials: {
    ['scene2 - var1']: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/цоколь00.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="6" geometry={nodes['6'].geometry} material={materials['scene2 - var1']} />
      <HoverMesh name="7" geometry={nodes['7'].geometry} material={materials['scene2 - var1']} />
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials['scene2 - var1']} position={[0, 0, 0.03]} />
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials['scene2 - var1']} position={[0, 0, 0.017]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials['scene2 - var1']} position={[0, 0, 0.056]} />
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials['scene2 - var1']} position={[-0.152, -0.333, 0.548]} rotation={[0, 0, Math.PI / 6]} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials['scene2 - var1']} position={[0.659, -0.189, 0.298]} rotation={[Math.PI / 2, 0, 0]} />
      <HoverMesh name="8" geometry={nodes['8'].geometry} material={materials['scene2 - var1']} position={[1.023, 0.731, -0.441]} />
      <HoverMesh name="ground" geometry={nodes.ground.geometry} material={materials['scene2 - var1']} />
      <HoverMesh name="base" geometry={nodes.base.geometry} material={materials['scene2 - var1']} position={[0, 0, -1.323]} />
      <HoverMesh name="cement" geometry={nodes.cement.geometry} material={materials['scene2 - var1']} />
      <HoverMesh name="armature" geometry={nodes.armature.geometry} material={materials['scene2 - var1']} position={[1.399, -1.225, -0.41]} rotation={[0, 0, -Math.PI / 2]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/цоколь00.glb')
