import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 --types --keepnames --root ./meshes -o walls21.tsx стены21.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['3']: THREE.Mesh
    ['4']: THREE.Mesh
    ['5']: THREE.Mesh
    ['1']: THREE.Mesh
    ['2']: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/стены21.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials.Material} />
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials.Material} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials.Material} position={[0, 0, -0.466]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials.Material} position={[0.708, -0.839, 0.244]} />
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials.Material} position={[0.667, -0.181, -0.032]} rotation={[Math.PI / 2, 0, 0]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/стены21.glb')
