import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconCheck = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Check_round_fill">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.8696 9.28032C19.5768 8.43177 19.4621 7.17064 18.6136 6.46351C17.765 5.75638 16.5039 5.87103 15.7968 6.71958L10.5321 13.0371L8.08075 10.5857C7.2997 9.80469 6.03337 9.80469 5.25232 10.5857C4.47127 11.3668 4.47127 12.6331 5.25232 13.4142L7.70371 15.8656L7.76334 15.9252L7.76336 15.9253C8.0711 16.2334 8.43149 16.5942 8.78133 16.8587C9.19997 17.1753 9.85675 17.5553 10.7353 17.5155C11.6138 17.4756 12.2335 17.0377 12.6217 16.6845C12.9462 16.3894 13.2725 15.9974 13.5511 15.6627L13.605 15.5979L18.8696 9.28032ZM9.92213 13.7326L9.92503 13.7302C9.92309 13.7319 9.92213 13.7326 9.92213 13.7326ZM11.1995 13.6723L11.2026 13.6745C11.2026 13.6745 11.2016 13.6739 11.1995 13.6723Z"
        fill="currentColor"
      />
    </g>
  </svg>
));
