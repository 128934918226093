import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o roof10.tsx крыша10.glb 
*/

import * as THREE from "three";
import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { POLYETHYLENE_OPACITY } from "config";

type GLTFResult = GLTF & {
  nodes: {
    ["1"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
  };
  materials: {
    ["Material.002"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/крыша10.glb") as GLTFResult;
  const m = useMemo(() => {
    const mat = materials["Material.002"].clone();
    mat.transparent = true;
    mat.opacity = POLYETHYLENE_OPACITY;
    return mat;
  }, []);
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["Material.002"]}
        position={[0, 0.022, 0]}
      />
      <HoverMesh
        name="9"
        geometry={nodes["9"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh name="8" geometry={nodes["8"].geometry} material={m} />
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["Material.002"]}
        position={[0, 0.022, 0]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["Material.002"]}
        position={[0, 0.022, 0]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["Material.002"]}
        position={[1.219, 0.218, -0.205]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["Material.002"]}
        position={[1.219, 0.218, -0.205]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["Material.002"]}
        position={[0, 0.022, 0]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["Material.002"]}
        position={[1.219, 0.218, -0.205]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/крыша10.glb')
