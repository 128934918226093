/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from "react";
import { Resize, useGLTF } from "@react-three/drei";
import { CenterResizeScale } from "../c/center-resize-scale";
import { MeshBasicMaterial } from "three";

export function House1Map(props) {
  const { nodes, materials } = useGLTF("./meshes/house1/house1.map.glb");
  useEffect(() => {
    // materials["Material.004"].color.setRGB(0.5, 0.5, 0.5);
    // materials["Material.004"].map = null;
    // materials["Material.004"].needsUpdate = true;
  }, []);
  // paint windows
  useMemo(() => {
    materials["Material.004"] = new MeshBasicMaterial({
      color: 0x606060,
    });
  }, []);
  return (
    <CenterResizeScale
      scale={[6, 6, 6]}
      position={[1.2, 1, 0]}
      rotation={[0, Math.PI / 2, 0]}
    >
      <group {...props} dispose={null}>
        <mesh
          name="PLITA_PEREKR_TIA_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.PLITA_PEREKR_TIA_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.001"]}
          position={[3.886, 0.297, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA.geometry
          }
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.002"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART.geometry
          }
          material={materials["Material.003"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          material={materials["Material.003"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="INTER_ER___MEBEL__DLA_VODOSTOKOV"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_VODOSTOKOV.geometry}
          material={materials["Material.003"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001
              .geometry
          }
          material={materials["Material.004"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002.geometry
          }
          material={materials["Material.004"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002
              .geometry
          }
          material={materials["Material.004"]}
          position={[3.886, 0.296, 10.597]}
        />
        <mesh
          name="Cube"
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials["Material.004"]}
          position={[2.471, 0.13, 15.479]}
          scale={[0.04, 0.25, 0.04]}
        />
      </group>
    </CenterResizeScale>
  );
}

useGLTF.preload("./meshes/house1/house1.map.glb");
