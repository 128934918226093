import React from "react";
import { Game } from "features/game";
import { Ui } from "features/ui";
import { Kb } from "features/kb";
import { DetailBg } from "features/ui/c/detail-bg";

const App = () => (
  <div className="w-screen h-screen bg-dark">
    <DetailBg />
    <Game />
    <Ui />
    <Kb />
  </div>
);

export default App;
