import { FC } from "react";
import { NumberCircle } from "./number-circle";

interface Props {
  fill: number;
  disabled?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

export const MapMarker: FC<Props> = ({
  fill,
  disabled,
  onClick,
  onMouseOut,
  onMouseOver,
}) => {
  if (disabled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="88"
        fill="none"
        viewBox="0 0 72 88"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <g filter="url(#filter0_d_307_580)" opacity="0.5">
          <path
            stroke="#fff"
            strokeWidth="8"
            d="M60 31.667c0 4.191-1.463 8.849-3.932 13.654-2.454 4.772-5.77 9.44-9.163 13.583-3.383 4.131-6.777 7.663-9.33 10.164-.571.56-1.1 1.067-1.575 1.517-.475-.45-1.004-.957-1.576-1.517-2.552-2.501-5.946-6.033-9.33-10.164-3.392-4.143-6.708-8.81-9.162-13.583C13.462 40.516 12 35.859 12 31.667 12 18.64 22.7 8 36 8s24 10.641 24 23.667z"
            shapeRendering="crispEdges"
          ></path>
        </g>
        <defs>
          <filter
            id="filter0_d_307_580"
            width="72"
            height="88"
            x="0"
            y="0"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dy="4"></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_307_580"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_307_580"
              result="shape"
            ></feBlend>
          </filter>
        </defs>
      </svg>
    );
  } else {
    return (
      <div
        className="relative cursor-pointer"
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="88"
          fill="none"
          viewBox="0 0 72 88"
          className="z-0"
        >
          <g filter="">
            <g filter="url(#filter1_d_307_638)">
              <path
                stroke="#fff"
                strokeWidth="8"
                d="M60 31.667c0 4.191-1.463 8.849-3.932 13.654-2.454 4.772-5.77 9.44-9.163 13.583-3.383 4.131-6.777 7.663-9.33 10.164-.571.56-1.1 1.067-1.575 1.517-.475-.45-1.004-.957-1.576-1.517-2.552-2.501-5.946-6.033-9.33-10.164-3.392-4.143-6.708-8.81-9.162-13.583C13.462 40.516 12 35.859 12 31.667 12 18.64 22.7 8 36 8s24 10.641 24 23.667z"
                shapeRendering="crispEdges"
              ></path>
            </g>
            <circle cx="36" cy="32" r="16" fill="#fff"></circle>
            <rect
              width="32"
              height="32"
              x="20"
              y="16"
              stroke="#fff"
              strokeWidth="8"
              rx="16"
            ></rect>
            <path
              fill="#242424"
              fillRule="evenodd"
              d="M29.274 28.126C29 28.723 29 29.402 29 30.76V35c0 1.887 0 2.83.586 3.415.531.532 1.357.58 2.914.585V34a2 2 0 012-2h3a2 2 0 012 2v5c1.557-.005 2.383-.054 2.914-.586C43 37.828 43 36.886 43 35v-4.24c0-1.358 0-2.037-.275-2.634-.274-.597-.79-1.038-1.82-1.922l-1-.857C38.04 23.75 37.11 22.95 36 22.95c-1.11 0-2.041.799-3.905 2.396l-1 .857c-1.03.884-1.546 1.325-1.82 1.922zM37.5 39v-5h-3v5h3z"
              clipRule="evenodd"
            ></path>
            <g filter="url(#filter2_d_307_638)"></g>
          </g>

          <defs>
            <filter
              id="filter0_d_307_638"
              width="64"
              height="80"
              x="4"
              y="4"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="4"></feOffset>
              <feGaussianBlur stdDeviation="2"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_307_638"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_307_638"
                result="shape"
              ></feBlend>
            </filter>
            <filter
              id="filter1_d_307_638"
              width="72"
              height="88"
              x="0"
              y="0"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="4"></feOffset>
              <feGaussianBlur stdDeviation="4"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_307_638"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_307_638"
                result="shape"
              ></feBlend>
            </filter>
            <filter
              id="filter2_d_307_638"
              width="36.502"
              height="44"
              x="21.498"
              y="11"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_307_638"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_307_638"
                result="shape"
              ></feBlend>
            </filter>
            <linearGradient
              id="paint0_linear_307_638"
              x1="26"
              x2="49.96"
              y1="32"
              y2="25.008"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00C2FF"></stop>
              <stop offset="1" stopColor="#9747FF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <div className="absolute top-4 left-5 z-0">
          <NumberCircle number={fill} max={15} hideNumber fat />
        </div>
      </div>
    );
  }
};
