import { useDispatch } from "app/store";
import { MdOutlineViewInAr } from "react-icons/md";
import { incDefaultView } from "../slice";
import { FC } from "react";
import classnames from "classnames";

interface Props {
  house?: boolean;
}

export const DefaultViewButton: FC<Props> = ({ house }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="fixed top-2 right-[23.5rem] cursor-pointer w-10 h-10 hover:opacity-75"
      onClick={() => dispatch(incDefaultView())}
    >
      <MdOutlineViewInAr
        className={classnames("w-10 h-10 drop-shadow-sm", {
          "text-gray-900": !house,
          "text-white": house,
        })}
      />
    </div>
  );
};
