import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o roof90.tsx крыша90.glb 
*/

import * as THREE from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { polyethylene } from "app/materials";

type GLTFResult = GLTF & {
  nodes: {
    ["10"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
  };
  materials: {
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["scene2 - var1.001"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/крыша90.glb") as GLTFResult;
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="10"
        geometry={nodes["10"].geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        name="8"
        geometry={nodes["8"].geometry}
        material={materials["Material.001"]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["Material.001"]}
        position={[0.004, -0.394, 0.089]}
      />
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["Material.001"]}
        position={[0.673, 0.257, -0.922]}
        rotation={[0, 0, 0.016]}
      />
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["Material.001"]}
        position={[1.049, 0.274, -1.036]}
        rotation={[0, 0, 0.014]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["Material.001"]}
        position={[-0.044, 0.002, -0.2]}
      />
      <HoverMesh
        name="9"
        geometry={nodes["9"].geometry}
        material={polyethylene}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/крыша90.glb')
