import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconRemove = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.2" cy="12.2" r="7.2" fill="#242424" />
    <path d="M8 12.8109V11.5891H16.4V12.8109H8Z" fill="white" />
  </svg>
));
