import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o interfloor41.tsx межэтажное41.glb 
*/

import * as THREE from "three";
import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    ["7"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
  };
  materials: {
    ["scene2 - var1"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/межэтажное41.glb"
  ) as GLTFResult;
  const mat2 = useMemo(() => {
    materials["scene2 - var1"].roughness = 0.25;
    // materials["scene2 - var1"].metalness = 0;
    materials["scene2 - var1"].roughnessMap = null;
    // materials["scene2 - var1"].normalMap = null;
    materials["scene2 - var1"].needsUpdate = true;

    return materials["scene2 - var1"];
  }, []);
  return (
    <HoverGroup {...props} dispose={null}>
      <ambientLight intensity={1} />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["scene2 - var1"]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["scene2 - var1"]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/межэтажное41.glb')
