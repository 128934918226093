import { useLoader } from "@react-three/fiber";
import { useSelector } from "app/store";
import { FileLoader } from "three";

export const DetailBg = () => {
  const house = useSelector(s => s.ui.house);
  const model = useSelector(s => s.ui.currentModel);
  const filename = `${house}-${model}.png`;
  const screen = useSelector(s => s.ui.screen);
  if (screen === "filter") return null;
  if (screen === "house") return null;
  return (
    // <img className="w-full h-screen blur-sm object-contain" src={filename} />
    <img className="w-full h-screen object-cover" src="model-background.png" />
  );
};

useLoader.preload(FileLoader, "1-крыша.png");
useLoader.preload(FileLoader, "1-стены.png");
useLoader.preload(FileLoader, "1-межэтажное.png");
useLoader.preload(FileLoader, "1-полы.png");
useLoader.preload(FileLoader, "1-цоколь.png");
useLoader.preload(FileLoader, "1-фундамент.png");

useLoader.preload(FileLoader, "2-крыша.png");
useLoader.preload(FileLoader, "2-стены.png");
useLoader.preload(FileLoader, "2-межэтажное.png");
useLoader.preload(FileLoader, "2-полы.png");
useLoader.preload(FileLoader, "2-цоколь.png");
useLoader.preload(FileLoader, "2-фундамент.png");
