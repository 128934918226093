import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

export type UiScreen =
  | "login"
  | "filter"
  | "house"
  | "house-detail"
  | "configurator";
export type FilterItem = "construction" | "roof" | "price";
export type NumberedFilterItem = "energy" | "constructionTime" | "lifetime";

// export const DB_ENTRIES = [
//   "крыша",
//   "межэтажное",
//   "полы",
//   "стены",
//   "фундамент",
//   "цоколь",
// ];

// export type DbEntry = keyof typeof DB_ENTRIES;
export type DbEntry =
  | "крыша"
  | "межэтажное"
  | "полы"
  | "стены"
  | "фундамент"
  | "цоколь";

export interface UiState {
  screen: UiScreen;
  filterItems: {
    construction: number[];
    roof: number[];
    price: number[];
  };
  numberedFilterItems: {
    energy: [number, number];
    constructionTime: [number, number];
    lifetime: [number, number];
  };
  house: 1 | 2;
  houses: HouseModel[];
  models: { [k: string]: Model };
  selection: SelectionModel;
  currentModel: DbEntry;
  currentVariant: number;
  currentTier: number;
  hoverCode: number;
  clickedHoverCode: number;
  uiVisible: boolean;
  defaultView: number;
  hoverSilent: boolean;
}

export interface Model {
  name: string;
  variants: [
    {
      code: number;
      name: string;
      description?: string;
      roofType?: string;
      tiers: [
        {
          name: string;
          price: string;
          properties: [
            {
              code: number;
              name: string;
              value: string;
            }
          ];
          stats: [
            {
              name: string;
              value: number;
            }
          ];
        }
      ];
    }
  ];
}

export interface HouseModel {
  name: string;
  model: 1 | 2;
  construction: string;
  roof: string;
  price: string;
  energy: number;
  constructionTime: number;
  lifetime: number;
  x: number;
  y: number;
  rotation: number;
}

export interface SelectionModel {
  house: number;
  options: { [k: string]: [number, number] };
}

const initialState: UiState = {
  screen: "filter",
  filterItems: {
    construction: [0, 1, 2, 3, 4, 5],
    roof: [0, 1],
    price: [0, 1, 2],
  },
  numberedFilterItems: {
    energy: [1, 5],
    constructionTime: [1, 5],
    lifetime: [1, 5],
  },
  house: 1,
  houses: [],
  models: {},
  selection: { house: 0, options: {} },
  currentModel: "стены",
  currentTier: 0,
  currentVariant: 0,
  hoverCode: -1,
  clickedHoverCode: -1,
  uiVisible: true,
  defaultView: 0,
  hoverSilent: false,
};

export const slice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setScreen: (state, { payload }: PayloadAction<UiScreen>) => {
      state.screen = payload;
    },
    setHouse: (state, { payload }: PayloadAction<1 | 2>) => {
      state.house = payload;
    },
    setFilterItems: (
      state,
      {
        payload,
      }: PayloadAction<{
        filter: "construction" | "roof" | "price";
        value: number[];
      }>
    ) => {
      state.filterItems[payload.filter] = payload.value;
    },
    setNumberedFilterItems: (
      state,
      {
        payload,
      }: PayloadAction<{ model: NumberedFilterItem; value: [number, number] }>
    ) => {
      state.numberedFilterItems[payload.model] = payload.value;
    },
    setHouses: (state, { payload }: PayloadAction<HouseModel[]>) => {
      state.houses = payload;
    },
    setModel: (
      state,
      {
        payload,
      }: PayloadAction<{
        entry: DbEntry;
        value: Model;
      }>
    ) => {
      state.models[payload.entry] = payload.value;
    },
    selectModel: (state, { payload }: PayloadAction<DbEntry>) => {
      state.currentModel = payload;
      if (state.house === 2 && payload === "крыша") {
        // some indian code
        state.currentVariant = 11; //state.models[payload].variants.length - 1;
      }
    },
    selectHouse: (state, { payload }: PayloadAction<number>) => {
      state.selection.house = payload;
      state.house = state.houses[payload].model;
    },
    selectOption: (
      state,
      {
        payload,
      }: PayloadAction<{ name: string; variant: number; tier: number }>
    ) => {
      state.selection.options[payload.name] = [payload.variant, payload.tier];
    },
    setVariant: (state, { payload }: PayloadAction<number>) => {
      state.currentVariant = payload;
      const v = state.models[state.currentModel].variants[state.currentVariant];
      if (!v.tiers[payload]) {
        state.currentTier = 0;
        // some more indian
        if (!v.tiers[0]) {
          state.currentTier = 1;
        }
      }
      state.defaultView++;
    },
    setTier: (state, { payload }: PayloadAction<number>) => {
      state.currentTier = payload;

      // state.defaultView++;
    },
    setHoverCode: (state, { payload }: PayloadAction<number>) => {
      state.hoverCode = payload;
      state.hoverSilent = false;
    },
    setHoverCodeFromList: (state, { payload }: PayloadAction<number>) => {
      state.hoverCode = payload;
      state.hoverSilent = true;
    },
    setClickedHoverCode: state => {
      state.clickedHoverCode = state.hoverCode;
    },
    toggleUi: state => {
      state.uiVisible = !state.uiVisible;
    },
    incDefaultView: state => {
      state.defaultView++;
    },
  },
});

export const {
  setScreen,
  setFilterItems,
  setNumberedFilterItems,
  setHouses,
  setModel,
  setTier,
  setVariant,
  setHoverCode,
  setHoverCodeFromList,
  selectModel,
  selectHouse,
  selectOption,
  setClickedHoverCode,
  toggleUi,
  incDefaultView,
} = slice.actions;

export default slice.reducer;
