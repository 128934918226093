const gx = 0.02;
const gy = 0.02;
const w = 750;
const l = 750;
const h = 0.05;
const d = 3;
const e = 3;
const ses = 0.05;

const r = 1.1;
const g = 1.5;
const b = 1.3;

export const vertexShader = `
            // attribute vec2 uv;
            // attribute vec3 position;
            attribute vec2 per;
            uniform sampler2D perlin;
            varying vec2 v_uv;
            varying vec3 posTest;
            varying float v_top;
            varying float v_p;
            uniform float time;

            mat4 rotateX(float angle) {
    float cosA = cos(angle);
    float sinA = sin(angle);
 return mat4(
        1.0, 0.0, 0.0, 0.0,
        0.0, cosA, -sinA, 0.0,
        0.0, sinA, cosA, 0.0,
        0.0, 0.0, 0.0, 1.0
    );
}
mat4 rotateY(float angle) {
    return mat4(
        vec4(cos(angle), 0.0, sin(angle), 0.0),
        vec4(0.0, 1.0, 0.0, 0.0),
        vec4(-sin(angle), 0.0, cos(angle), 0.0),
        vec4(0.0, 0.0, 0.0, 1.0)
    );
}
mat3 extractRotation(mat4 mat) {
    return mat3(mat[0].xyz, mat[1].xyz, mat[2].xyz);
}
vec3 extractRotationAngles(mat4 modelViewMatrix) {
    mat3 rotationMatrix = extractRotation(modelViewMatrix);
    
    float pitch = atan(rotationMatrix[1].z, rotationMatrix[2].z);
    float yaw = atan(-rotationMatrix[0].z, sqrt(rotationMatrix[1].z * rotationMatrix[1].z + rotationMatrix[2].z * rotationMatrix[2].z));
    float roll = atan(rotationMatrix[0].y, rotationMatrix[0].x);

    return vec3(pitch, yaw, roll);
}

vec4 rotateObjectTowardsCamera(vec3 position, float cameraYaw) {
    // Extract the position from the original model matrix
    // Construct a rotation matrix using the camera's yaw angle
    mat4 rotationMatrix = mat4(
        vec4(cos(cameraYaw), 0.0, sin(cameraYaw), 0.0),
        vec4(0.0, 1.0, 0.0, 0.0),
        vec4(-sin(cameraYaw), 0.0, cos(cameraYaw), 0.0),
        vec4(0.0, 0.0, 0.0, 1.0)
    );

    // Apply the rotation to the object's position
    vec4 rotatedPosition = rotationMatrix * vec4(position, 1.0);
    return rotatedPosition;
}

            void main() {
                vec3 pos = position;
                float m = pos.y / 1.;
                float p = texture2D(perlin, fract(per + vec2(time * 0.15, time * 0.05))).r;
                pos.y -= p * m * 0.1;
                pos.x -= p * m * 0.1;
                pos.z -= p * m * 0.033;
                v_uv = uv;
                v_top = m;
                v_p = p;
                posTest = pos;
                vec3 angles = extractRotationAngles(modelViewMatrix);
                vec4 modelPos =  modelViewMatrix * rotateX(clamp(cameraPosition.y * 0.5, 0.2, 4.) * m * ${ses.toFixed(
                  3
                )}) * vec4(pos, 1.0);
                // vec4 modelPos = modelViewMatrix * vec4(pos, 1.);
                gl_Position = projectionMatrix * modelPos;
  
            }
        `;

export const fragmentShader = `
            uniform sampler2D map;
            uniform sampler2D aoMap;
            varying vec2 v_uv;
            varying float v_top;
            varying float v_p;
            varying vec3 posTest;

            void main() {
                vec4 color = texture2D(map, v_uv);
                if (color.a <= 0.8) {
                  discard;
                }
                color.r *= ${r.toFixed(2)};
                color.g *= ${g.toFixed(2)};
                color.b *= ${b.toFixed(2)};
                vec3 ao = texture2D(aoMap, v_uv).rgb;
                // vec3 finalColor = color.rgb * (1.0 - ao);

                gl_FragColor = vec4(color.rgb * clamp(v_top, 0.1, 1.0) * mix(0.4, 1.0, v_p), 1.0);
            }
        `;
