import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconRoof = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 4.45545L22.2704 15.2964C22.9351 15.9981 22.9052 17.1057 22.2036 17.7704C21.5019 18.4351 20.3943 18.4052 19.7296 17.7036L12.5445 10.1193L12 9.54455L11.4555 10.1193L4.27042 17.7036C3.60572 18.4052 2.49808 18.4351 1.79645 17.7704L1.28729 18.3079L1.79645 17.7704C1.09482 17.1057 1.06488 15.9981 1.72959 15.2964L1.18512 14.7806L1.72959 15.2964L12 4.45545ZM14 16.75C14.1381 16.75 14.25 16.8619 14.25 17V18C14.25 18.1381 14.1381 18.25 14 18.25H10C9.86193 18.25 9.75001 18.1381 9.75001 18V17C9.75001 16.8619 9.86193 16.75 10 16.75H14ZM19.75 6C19.75 5.86193 19.8619 5.75 20 5.75H21C21.1381 5.75 21.25 5.86193 21.25 6V9C21.25 9.13807 21.1381 9.25 21 9.25H20C19.8619 9.25 19.75 9.13807 19.75 9V6Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
