export const PLAYER_RADIUS = 0.25;

export const CONSTRUCTION_FILTER_ITEMS = [
  "каркасный",
  "из легких блоков",
  "из дерева",
  "каркасно-монолитный",
  "сип",
  "лстк",
];

export const ROOF_FILTER_ITEMS = ["скатная", "плоская"];
export const PRICE_FILTER_ITEMS = ["эконом", "стандарт", "премиум"];
export const POLYETHYLENE_OPACITY = 0.5;
