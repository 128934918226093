import { FC } from "react";
import { IconRemove } from "./icons/remove";
import { IconAdd } from "./icons/add";
import c from "classnames";

interface Props {
  items: string[];
  activeItems: number[];
  onItemSelect: (item: number | "all") => void;
  onItemDeselect: (item: number | "all") => void;
}

const allSelected = (items: string[], activeItems: number[]) => {
  for (let i = 0; i < items.length; i++) {
    if (!activeItems.includes(i)) {
      return false;
    }
  }
  return true;
};

const activeClass =
  "px-4 py-1 border border-gradient cursor-pointer hover:opacity-75";
const inactiveClass =
  "px-4 py-1 bg-gray border border-transparent cursor-pointer hover:opacity-75";

export const FilterSelector: FC<Props> = ({
  items,
  activeItems,
  onItemSelect,
  onItemDeselect,
}) => {
  const all = allSelected(items, activeItems);
  return (
    <div className="selectors-container pl-8 flex flex-col gap-2">
      {/* <div
        className={all ? activeClass : inactiveClass}
        onClick={() => (all ? onItemDeselect("all") : onItemSelect("all"))}
      >
        все
      </div> */}
      {items.map((item, i) => {
        const active = activeItems.includes(i);
        return (
          <div
            key={i}
            onClick={() => (active ? onItemDeselect(i) : onItemSelect(i))}
            className="cursor-pointer hover:opacity-75"
          >
            <div
              className={c(
                "flex gap-4 justify-start items-center py-1 opacity-container",
                {
                  "opacity-40": !active,
                }
              )}
            >
              {active ? (
                <IconRemove className="w-6 h-6" />
              ) : (
                <IconAdd className="w-6 h-6" />
              )}
              <div className="text-1rem uppercase font-display tracking-wider">
                {item}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
