import { Canvas } from "@react-three/fiber";
import { Scene } from "./c/scene";
import { Euler } from "three";
import { Suspense } from "react";
import { Loader } from "@react-three/drei";

export const Game = () => (
  <div className="absolute lef-0 top-0 w-full h-screen">
    <Canvas shadows="soft">
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </Canvas>
    <Loader />
  </div>
);
