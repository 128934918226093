import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconList = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/task">
      <g id="task">
        <path
          id="Vector"
          d="M11 19.5H21"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M11 12.5H21"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M11 5.5H21"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M3 5.5L4 6.5L7 3.5"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_5"
          d="M3 12.5L4 13.5L7 10.5"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_6"
          d="M3 19.5L4 20.5L7 17.5"
          stroke="#242424"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
));
