import { useRef, useState } from "react";
import {
  SoftShadows,
  RoundedBox,
  PointerLockControls,
  useCursor,
  OrbitControls,
  Resize,
  MeshTransmissionMaterial,
  Billboard,
  Outlines,
  MeshPortalMaterial,
  // Effects,
  Environment,
  MapControls,
} from "@react-three/drei";
import { Bobbing } from "./bobbing";
import { Camera } from "./camera";
import { KbMovable } from "./kbmovable";
import { Skybox } from "./skybox";
import { Box } from "./box";
import { Demo } from "./demo";
import { House1 } from "./house1";
import { Euler, ShadowMaterial, Vector3 } from "three";
import { useDispatch, useSelector } from "app/store";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { ModelSelector } from "./model-selector";
import { CenterResizeScale } from "./center-resize-scale";
import { Grass } from "./grass";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { Effects } from "./effects";
import { useEventListener } from "app/event-listener";
import { PI, clamp } from "app/math";
import { CameraControls } from "./camera-controls";
import { House2 } from "./house2";
import { Porch } from "./porch";
import { FilterScene } from "./filter-scene";
import { CameraLogger } from "./camera-logger";
import { toggleUi } from "features/ui/slice";
import { HouseCameraAnimator } from "./house-camera-animator";

export const Scene = () => {
  const screen = useSelector(s => s.ui.screen);
  const dispatch = useDispatch();
  const mainScene = ["house"].includes(screen);
  const strictCamera = mainScene;
  const configurationScene = ["house-detail", "configurator"].includes(screen);
  const filterScene = ["filter"].includes(screen);

  const house = useSelector(s => s.ui.house);

  useEventListener("keydown", e => {
    if (e.code === "Space") {
      dispatch(toggleUi());
    }
  });

  return filterScene ? (
    <FilterScene />
  ) : (
    <group>
      {/* <Effects
        multisamping={8}
        renderIndex={1}
        disableGamma={false}
        disableRenderPass={false}
        disableRender={false}
      ></Effects> */}
      {/*<Skybox />*/}
      {/* <Box /> */}
      {/* <ambientLight intensity={0.5} /> */}
      {/* <pointLight position={[10, 10, 10]} intensity={1.0} /> */}

      {!configurationScene && (
        <>
          <Effects />
          <Environment
            blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
            background
            files={"limpopo_golf_course_4k.hdr"}
          />
          {/* <directionalLight castShadow /> */}

          {/* temporary change */}
          <CameraControls animate />
          <HouseCameraAnimator />
          {/* <MapControls enableDamping={false} /> */}
          {/* <CameraLogger /> */}

          <CenterResizeScale
            scale={[14, 14, 14]}
            position={[1.25, -0.6, 0.0]}
            rotation={[0, Math.PI, 0]}
          >
            {house === 1 ? (
              <>
                <House1 />
                <Box scale={[100, 0.2, 100]} receiveShadow position={[0, 0, 5]}>
                  <shadowMaterial transparent opacity={0.25} />
                </Box>
                <Box
                  scale={[4.15, 10.75, 0.03]}
                  rotation={[0, 0, 0.0]}
                  receiveShadow
                  position={[5.85, 2.6, 11.1]}
                >
                  <shadowMaterial transparent opacity={0.35} />
                  {/* <meshBasicMaterial color="yellow" /> */}
                </Box>
                {/*<Box
              scale={[0.3, 5.75, 0.03]}
              rotation={[0, 0, 0]}
              receiveShadow
              position={[4.6, 0., 11.1]}
            >
              <meshBasicMaterial color="black" transparent opacity={0.35} /> 
            </Box>*/}
                <Box
                  scale={[0.38, 0.03, 2.03]}
                  rotation={[0, 0.03, 0]}
                  receiveShadow
                  position={[4, 0.3, 11.9]}
                >
                  {/* <shadowMaterial transparent opacity={0.35} /> */}
                  <meshBasicMaterial color="black" transparent opacity={0.43} />
                </Box>
                <Box
                  scale={[8.15, 1.2, 0.03]}
                  rotation={[0, 0, -0.03]}
                  receiveShadow
                  position={[7.85, 6.2, 12.25]}
                >
                  <shadowMaterial transparent opacity={0.35} />
                  {/* <meshBasicMaterial color="yellow" /> */}
                </Box>
              </>
            ) : (
              <>
                <House2 />
                <Porch />
                {/* quick fix */}
                <Box
                  scale={[100, 0.2, 100]}
                  receiveShadow
                  position={[0, 0, 5]}
                  visible={false}
                >
                  <shadowMaterial transparent opacity={0.25} />
                </Box>
                <Box
                  scale={[4.15, 10.75, 0.03]}
                  rotation={[0, 0, 0.0]}
                  receiveShadow
                  position={[5.85, 2.6, 11.1]}
                  visible={false}
                >
                  <shadowMaterial transparent opacity={0.35} />
                  {/* <meshBasicMaterial color="yellow" /> */}
                </Box>
                {/*<Box
              scale={[0.3, 5.75, 0.03]}
              rotation={[0, 0, 0]}
              receiveShadow
              position={[4.6, 0., 11.1]}
            >
              <meshBasicMaterial color="black" transparent opacity={0.35} /> 
            </Box>*/}
                <Box
                  scale={[0.38, 0.03, 2.03]}
                  rotation={[0, 0.03, 0]}
                  receiveShadow
                  position={[4, 0.3, 11.9]}
                  visible={false}
                >
                  {/* <shadowMaterial transparent opacity={0.35} /> */}
                  <meshBasicMaterial color="black" transparent opacity={0.43} />
                </Box>
                <Box
                  scale={[8.15, 1.2, 0.03]}
                  rotation={[0, 0, -0.03]}
                  receiveShadow
                  position={[7.85, 6.2, 12.25]}
                  visible={false}
                >
                  <shadowMaterial transparent opacity={0.35} />
                  {/* <meshBasicMaterial color="yellow" /> */}
                </Box>
              </>
            )}
            <Grass />

            {/* <Box scale={[10, 12, 20]} castShadow position={[10, 2.5, 5]} /> */}
            <pointLight castShadow intensity={0.5} position={[5, 25, 30]} />
            {/*<Box position={[5, 25, 30]} color="red" />*/}
          </CenterResizeScale>
        </>
      )}
      {/*<OrbitControls
        ref={orbitControls}
        enablePan={false}
        // enableRotate
        enableZoom
        // minPolarAngle={-Math.PI / 2}
        // minPolarAngle={strictCamera ? Math.PI / 2 : -Math.PI / 2}
        // maxPolarAngle={Math.PI / 2}
        // maxDistance={4}
        // minDistance={1.8}
        target={[0.0, configurationScene ? 0.0 : -0.9, -0.0]}
        // screenSpacePanning
        // autoRotate
        // autoRotateSpeed={2}
          />*/}
      {/*<MapControls
        target={[0.0, -0.9, -0.0]}
      />*/}

      {configurationScene && (
        <>
          <CameraControls target={[0, -1.5, 0]} height={0.05} />
          <Environment
            background={false} // can be true, false or "only" (which only sets the background) (default: false)
            blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
            files={["alt_bk.png"]}
            preset={"warehouse"}
            scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
            encoding={undefined} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
          />
          {/* <ambientLight intensity={0.75} /> */}
          {/* <pointLight intensity={0.6} position={[5, 5, 5]} /> */}
          <ModelSelector />
        </>
      )}
    </group>
  );
};
