import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o floors30.tsx полы30.glb 
*/

import * as THREE from "three";
import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    ["2"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
  };
  materials: {
    ["scene2 - var1.004"]: THREE.MeshStandardMaterial;
    ["mat2"]: THREE.MeshStandardMaterial;
    ["mat3"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/полы30.glb") as GLTFResult;

  const mat2 = useMemo(() => {
    // foil material
    materials["mat2"] = materials["scene2 - var1.004"].clone();
    materials["mat2"].roughness = 0;
    materials["mat2"].metalness = 0;
    // materials["mat2"].metalnessMap = null;
    // materials["mat2"].normalMap = null;
    // materials["mat2"].roughnessMap = null;

    // geotextile material
    materials["mat3"] = materials["scene2 - var1.004"].clone();
    materials["mat3"].normalMap = null;
    // update the default material
    materials["scene2 - var1.004"].roughness = 0.5;
    materials["scene2 - var1.004"].roughnessMap = null;
    materials["scene2 - var1.004"].needsUpdate = true;

    return materials["mat2"];
  }, []);
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.363, 0.591, -0.488]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["mat2"]}
        position={[-0.469, 0.534, -0.494]}
      />
      <HoverMesh
        name="8"
        geometry={nodes["8"].geometry}
        material={materials["mat3"]}
        position={[-0.358, 0.135, -0.35]}
      />
      <HoverMesh
        name="9"
        geometry={nodes["9"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.14, 0, -0.134]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.248, 0.21, -0.239]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.521, 0.495, -0.371]}
      />
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.613, 0.656, -0.838]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[-0.569, 0.635, -0.343]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["scene2 - var1.004"]}
        position={[0.085, 0.372, -0.047]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/полы30.glb')
