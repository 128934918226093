import { FC, ReactNode } from "react";
import c from "classnames";

interface Props {
  position?: "left" | "right";
  title?: string;
  children?: ReactNode;
}

export const Panel: FC<Props> = ({ position = "right", title, children }) => {
  return (
    <div
      className={c("fixed top-0 h-screen", {
        "right-0": position === "right",
        "left-0": position === "left",
      })}
    >
      <div
        className={c(
          "panel-container absolute h-screen w-full md:w-[23rem] flex flex-col justify-start items-start transform transition-transform",
          {
            "right-0": position === "right",
            "left-0": position === "left",
          }
        )}
      >
        <div className="filter-widget w-full md:w-[23rem] h-screen flex flex-col">
          {!!title && (
            <div className="flex justify-between items-center py-4 pl-6 pr-4 bg-dark text-white">
              <h2 className="font-display uppercase text-1.5rem">{title}</h2>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
