export const unnecessaryBushes = [
  [3.201, 0.001, 21.49],
  [8.894, 0.001, 21.49],
  [5.13, -0.039, 20.374],
  [2.418, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [6.765, -0.039, 21.865],
  [2.418, -0.039, 21.865],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [5.13, -0.039, 20.374],
  [8.241, -0.039, 16.746],
  [-4.712, -0.039, 16.7],
  [-0.408, -0.039, 16.7],
  [-3.658, -0.039, 15.914],
  [-3.658, -0.039, 15.914],
  [-3.658, -0.039, 15.914],
  [-3.658, -0.039, 15.914],
  [-2.398, -0.039, 11.637],
  [-9.434, -0.039, 4.91],
  [-8.505, -0.039, 5.61],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-7.294, -0.039, 1.498],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-5.364, -0.039, 4.91],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-9.434, -0.039, 4.91],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-7.087, -0.039, 1.536],
  [-3.275, -0.039, -1.169],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-7.868, -0.039, 0.923],
  [-3.547, -0.039, 0.923],
  [-3.547, -0.039, 0.923],
  [-4.256, -0.039, -4.964],
  [-4.256, -0.039, -4.964],
  [0.418, -0.039, -4.964],
  [0.418, -0.039, -4.964],
  [22.159, 0.013, 4.644],
  [22.159, 0.013, 4.644],
  [22.159, 0.013, 4.644],
  [22.159, 0.013, 4.644],
  [28.528, 0.013, 4.644],
  [22.209, -0.039, 5.972],
  [24.246, -0.039, 8.968],
  [23.849, -0.039, 4.725],
  [22.209, -0.039, 5.972],
  [22.209, -0.039, 5.972],
  [27.037, -0.039, 5.972],
  [27.037, -0.039, 5.972],
  [22.209, -0.039, 5.972],
  [-4.712, -0.039, 16.7],
  [-3.658, -0.039, 15.914],
];
