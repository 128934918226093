import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconChevronLeft = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path d="M15 6.5L9 12.5L15 18.5" stroke="currentColor" stroke-width="2" />
  </svg>
));
