import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

// ЦОКОЛЬ
export const IconBase = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 12C7.25 12.1381 7.13807 12.25 7 12.25H3C2.86193 12.25 2.75 12.1381 2.75 12L2.75 11C2.75 10.8619 2.86193 10.75 3 10.75H7C7.13807 10.75 7.25 10.8619 7.25 11V12ZM7.25 4C7.25 4.13807 7.13807 4.25 7 4.25L3 4.25C2.86193 4.25 2.75 4.13807 2.75 4L2.75 3C2.75 2.86193 2.86193 2.75 3 2.75L7 2.75C7.13807 2.75 7.25 2.86193 7.25 3V4ZM10.25 8C10.25 8.13807 10.1381 8.25 10 8.25L6 8.25C5.86193 8.25 5.75 8.13807 5.75 8L5.75 7C5.75 6.86193 5.86193 6.75 6 6.75L10 6.75C10.1381 6.75 10.25 6.86193 10.25 7V8ZM14.25 12C14.25 12.1381 14.1381 12.25 14 12.25H10C9.86193 12.25 9.75 12.1381 9.75 12V11C9.75 10.8619 9.86193 10.75 10 10.75H14C14.1381 10.75 14.25 10.8619 14.25 11V12ZM14.25 4C14.25 4.13807 14.1381 4.25 14 4.25L10 4.25C9.86193 4.25 9.75 4.13807 9.75 4V3C9.75 2.86193 9.86193 2.75 10 2.75L14 2.75C14.1381 2.75 14.25 2.86193 14.25 3V4ZM17.25 8C17.25 8.13807 17.1381 8.25 17 8.25L13 8.25C12.8619 8.25 12.75 8.13807 12.75 8V7C12.75 6.86193 12.8619 6.75 13 6.75L17 6.75C17.1381 6.75 17.25 6.86193 17.25 7V8ZM21.25 12C21.25 12.1381 21.1381 12.25 21 12.25H17C16.8619 12.25 16.75 12.1381 16.75 12V11C16.75 10.8619 16.8619 10.75 17 10.75H21C21.1381 10.75 21.25 10.8619 21.25 11V12ZM21.25 4C21.25 4.13807 21.1381 4.25 21 4.25L17 4.25C16.8619 4.25 16.75 4.13807 16.75 4V3C16.75 2.86193 16.8619 2.75 17 2.75L21 2.75C21.1381 2.75 21.25 2.86193 21.25 3V4ZM2.75 16C2.75 15.8619 2.86193 15.75 3 15.75H21C21.1381 15.75 21.25 15.8619 21.25 16V20C21.25 20.1381 21.1381 20.25 21 20.25H3C2.86193 20.25 2.75 20.1381 2.75 20V16Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
