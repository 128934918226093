import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.2 --types --keepnames --root ./meshes -o floors32.tsx полы32.glb 
*/

import * as THREE from "three";
import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    ["2"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["10"]: THREE.Mesh;
    ["11"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
  };
  materials: {
    ["scene2 - var1"]: THREE.MeshStandardMaterial;
    ["mat2"]: THREE.MeshStandardMaterial;
    ["mat3"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/полы32.glb") as GLTFResult;
  const mat2 = useMemo(() => {
    // foil material
    materials["mat2"] = materials["scene2 - var1"].clone();
    materials["mat2"].roughness = 0;
    materials["mat2"].metalness = 0;
    // materials["mat2"].normalMap = null;
    // materials["mat2"].roughnessMap = null;

    // geotextile material
    materials["mat3"] = materials["scene2 - var1"].clone();
    materials["mat3"].normalMap = null;
    // update the default material
    materials["scene2 - var1"].roughness = 0.5;
    materials["scene2 - var1"].roughnessMap = null;
    materials["scene2 - var1"].needsUpdate = true;

    return materials["mat2"];
  }, []);
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.363, 0.63, -0.488]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["mat2"]}
        position={[-0.469, 0.574, -0.494]}
      />
      <HoverMesh
        name="10"
        geometry={nodes["10"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.197, 0.133, -0.188]}
      />
      <HoverMesh
        name="11"
        geometry={nodes["11"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.14, 0, -0.134]}
      />
      <HoverMesh
        name="9"
        geometry={nodes["9"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.248, 0.18, -0.239]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.525, 0.535, -0.388]}
      />
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.613, 0.695, -0.838]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.569, 0.675, -0.343]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.404, 0.503, -0.391]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["scene2 - var1"]}
        position={[0.085, 0.4, -0.047]}
      />
      <HoverMesh
        name="8"
        geometry={nodes["8"].geometry}
        material={materials["scene2 - var1"]}
        position={[-0.248, 0.268, -0.239]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/полы32.glb')
