/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";

export function MapScene(props) {
  const { nodes, materials } = useGLTF("./meshes/map.final.v2.glb");
  useEffect(() => {
    materials.Grass.roughness = 1;
    materials.Grass.metalness = 0.5;
  }, []);
  return (
    <group {...props} dispose={null}>
      <group position={[40.282, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_1.geometry}
          material={materials.plane}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_2.geometry}
          material={materials.sphere}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_3.geometry}
          material={materials.Grass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_4.geometry}
          material={materials.Asphalt}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_5.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_6.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_7.geometry}
          material={materials["Material.018"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_8.geometry}
          material={materials.trunk}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_9.geometry}
          material={materials["plane.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_10.geometry}
          material={materials["sphere.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_11.geometry}
          material={materials["Material.013"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_12.geometry}
          material={materials.plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_13.geometry}
          material={materials.glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_14.geometry}
          material={materials.light_red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_15.geometry}
          material={materials.light_orange}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_16.geometry}
          material={materials.light_white}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_17.geometry}
          material={materials.wheel}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_18.geometry}
          material={materials.rims}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_19.geometry}
          material={materials.grill}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_20.geometry}
          material={materials["Material.014"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_21.geometry}
          material={materials["Material.016"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_22.geometry}
          material={materials["Material.017"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_23.geometry}
          material={materials.Color_C08}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_24.geometry}
          material={materials.Blacktop_Old_02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_25.geometry}
          material={materials.Translucent_Glass_Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001_26.geometry}
          material={materials.Color_C03}
        />
      </group>
      <group position={[40.282, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Base_Skin_0035.geometry}
          material={materials.Skin}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Base_Skin_0035_1.geometry}
          material={materials.Shoes}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Base_Skin_0035_2.geometry}
          material={materials.Hoodie}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Base_Skin_0035_3.geometry}
          material={materials.Pants}
        />
      </group>
      {/*
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["1"].geometry}
        material={nodes["1"].material}
        position={[-2.133, 0.194, 3.494]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["2"].geometry}
        material={nodes["2"].material}
        position={[-2.344, 0.194, 2.193]}
        rotation={[0, 0.156, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["10"].geometry}
        material={nodes["10"].material}
        position={[1.313, 0.194, 0.547]}
        rotation={[0, 0.092, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["14"].geometry}
        material={nodes["14"].material}
        position={[3.49, 0.194, 0.93]}
        rotation={[0, 0.183, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["12"].geometry}
        material={nodes["12"].material}
        position={[2.679, 0.194, -0.87]}
        rotation={[0, -1.425, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["9"].geometry}
        material={nodes["9"].material}
        position={[0.783, 0.194, -1.401]}
        rotation={[0, 0.153, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["6"].geometry}
        material={nodes["6"].material}
        position={[-0.824, 0.194, -1.114]}
        rotation={[0, 0.153, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["15"].geometry}
        material={nodes["15"].material}
        position={[5.075, 0.194, -0.96]}
        rotation={[0, -0.003, 0]}
        scale={0.166}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["3"].geometry}
        material={nodes["3"].material}
        position={[-1.969, 0.194, 1.438]}
        rotation={[0, 0.117, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["8"].geometry}
        material={nodes["8"].material}
        position={[-0.61, 0.194, 2.117]}
        rotation={[0, 0.215, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["7"].geometry}
        material={nodes["7"].material}
        position={[-0.402, 0.194, -0.197]}
        rotation={[0, 0.142, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["5"].geometry}
        material={nodes["5"].material}
        position={[-2.045, 0.194, -0.836]}
        rotation={[0, 0.142, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["16"].geometry}
        material={nodes["16"].material}
        position={[1.123, 0.194, -0.486]}
        rotation={[0, 0.008, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["11"].geometry}
        material={nodes["11"].material}
        position={[1.401, 0.194, 1.471]}
        rotation={[0, 0.182, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["13"].geometry}
        material={nodes["13"].material}
        position={[3.16, 0.194, 0.017]}
        rotation={[0, 0.182, 0]}
        scale={[0.108, 0.166, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["4"].geometry}
        material={nodes["4"].material}
        position={[-1.94, 0.194, 0.673]}
        rotation={[0, 0.117, 0]}
        scale={[0.108, 0.166, 0.166]}
      />*/}
    </group>
  );
}

useGLTF.preload("./meshes/map.final.v2.glb");
