import { useFrame, useThree } from "@react-three/fiber";
import { useEventListener } from "app/event-listener";
import { PI, clamp, rad } from "app/math";
import { useDispatch, useSelector } from "app/store";
import {
  DbEntry,
  selectModel,
  setScreen,
  setTier,
  setVariant,
} from "features/ui/slice";
import { FC, useRef, useState } from "react";
import { Vector3 } from "three";

const MAX_HEIGHT = 7.1;

interface Props {
  upAndDown?: boolean;
  target?: [number, number, number];
  height?: number;
  animate?: boolean;
}

export const MapCameraControls: FC<Props> = ({
  upAndDown,
  target: _target,
  height = 4,
  animate,
}) => {
  const three = useThree();
  const pointerDown = useRef<boolean>(false);
  const dragging = useRef<boolean>(false);
  const target = useRef(new Vector3(...(_target || [5, 0, -1])));
  const angle = useRef<number>(-PI / 2 - PI / 12);
  const radius = useRef<number>(/*height*/ MAX_HEIGHT);

  // handle canvas clicks
  const dispatch = useDispatch();
  const selection = useSelector(s => s.ui.selection);
  const hc = useSelector(s => s.ui.hoverCode);
  const chc = useSelector(s => s.ui.clickedHoverCode);
  const house = useSelector(s => s.ui.house);
  const [destination, setDestination] = useState<string>("");

  // prevent right click context menu
  useEventListener("contextmenu", (e: MouseEvent) => {
    if (!e.ctrlKey) {
      e.preventDefault();
    }
  });
  useEventListener("wheel", (e: WheelEvent) => {
    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.tagName !== "CANVAS"
    )
      return;
    const delta = e.deltaY;
    radius.current = clamp(radius.current + delta * 0.01, height, MAX_HEIGHT);
  });
  useEventListener("pointerdown", e => {
    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.tagName !== "CANVAS"
    )
      return;
    if (e.button === 0) {
      dragging.current = true;
    }
    if (e.button === 2) {
      pointerDown.current = true;
      e.preventDefault();
    }
  });
  useEventListener("pointermove", e => {
    if (dragging.current) {
      const x = e.movementX;
      const y = e.movementY;
      target.current.z +=
        (x * Math.cos(angle.current) - y * Math.sin(angle.current)) *
        0.004 *
        radius.current;
      target.current.x -=
        (x * Math.sin(angle.current) + y * Math.cos(angle.current)) *
        0.004 *
        radius.current;

      target.current.x = clamp(target.current.x, -4, 26);
      target.current.z = clamp(target.current.z, -6, 4);
    }
    if (pointerDown.current) {
      if (destination) {
        // disable animation when the user starts to drag manually
        setDestination("");
      }
      if (upAndDown) {
        const y = e.movementY || 0;
        target.current.y = clamp(target.current.y + y * 0.005, -1.8, 0.4);
      }
      const x = e.movementX;

      angle.current = (angle.current + rad(x * 0.3)) % (PI * 2);
    }
  });
  useEventListener("pointerup", () => {
    pointerDown.current = false;
    dragging.current = false;
  });
  useFrame((three, delta) => {
    three.camera.position.y =
      height * 0.7 + 1 * Math.pow(radius.current - height, 2);
    three.camera.position.x =
      target.current.x + radius.current * Math.cos(angle.current);
    three.camera.position.z =
      target.current.z + radius.current * Math.sin(angle.current);

    three.camera.lookAt(target.current);

    // use these logs to find desired camera positions
    // console.log({ radius: radius.current, angle: angle.current });
  });
  return null;
};
