import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group";/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o floors21.tsx полы21.glb 
*/

import * as THREE from 'three'
import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    ['4']: THREE.Mesh
    ['9']: THREE.Mesh
    ['8']: THREE.Mesh
    ['7']: THREE.Mesh
    ['2']: THREE.Mesh
    ['5']: THREE.Mesh
    ['3']: THREE.Mesh
    ['1']: THREE.Mesh
    ['6']: THREE.Mesh
  }
  materials: {
    ['scene2 - var1']: THREE.MeshStandardMaterial
  }
  
}

export function Model(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('./meshes/полы21.glb') as GLTFResult
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh name="4" geometry={nodes['4'].geometry} material={materials['scene2 - var1']} position={[-0.533, 0.507, -0.368]} />
      <HoverMesh name="9" geometry={nodes['9'].geometry} material={materials['scene2 - var1']} position={[-0.088, 0, -0.083]} />
      <HoverMesh name="8" geometry={nodes['8'].geometry} material={materials['scene2 - var1']} position={[-0.158, 0.134, -0.151]} />
      <HoverMesh name="7" geometry={nodes['7'].geometry} material={materials['scene2 - var1']} position={[-0.22, 0.211, -0.206]} />
      <HoverMesh name="2" geometry={nodes['2'].geometry} material={materials['scene2 - var1']} position={[-0.356, 0.584, -0.432]} />
      <HoverMesh name="5" geometry={nodes['5'].geometry} material={materials['scene2 - var1']} position={[-0.397, 0.475, -0.382]} />
      <HoverMesh name="3" geometry={nodes['3'].geometry} material={materials['scene2 - var1']} position={[-0.561, 0.539, -0.468]} />
      <HoverMesh name="1" geometry={nodes['1'].geometry} material={materials['scene2 - var1']} position={[-0.625, 0.634, -0.712]} />
      <HoverMesh name="6" geometry={nodes['6'].geometry} material={materials['scene2 - var1']} position={[0.085, 0.373, -0.047]} />
    </HoverGroup>
  )
}

// useGLTF.preload('./meshes/полы21.glb')
