/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshBasicMaterial } from "three";

export function House2Map(props) {
  const { nodes, materials } = useGLTF("./meshes/house2.remove.vx.colors.glb");
  useMemo(() => {
    materials["Material.002"] = new MeshBasicMaterial({
      color: 0x606060,
    });
  }, []);
  return (
    <group {...props} dispose={null}>
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_TRUB_"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_TRUB_.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="OBOLOCKA___KROVLA_DLA_TRUB_"
        castShadow
        receiveShadow
        geometry={nodes.OBOLOCKA___KROVLA_DLA_TRUB_.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="INTER_ER___MEBEL__DLA_OKNA_FLAT_PART"
        castShadow
        receiveShadow
        geometry={nodes.INTER_ER___MEBEL__DLA_OKNA_FLAT_PART.geometry}
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J"
        castShadow
        receiveShadow
        geometry={
          nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J.geometry
        }
        material={materials["Material.007"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="OBOLOCKA___KROVLA_KROVLA_1_SMOOTH_PART"
        castShadow
        receiveShadow
        geometry={nodes.OBOLOCKA___KROVLA_KROVLA_1_SMOOTH_PART.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_.geometry}
        material={materials["Material.008"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA"
        castShadow
        receiveShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA.geometry}
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA"
        castShadow
        receiveShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_"
        castShadow
        receiveShadow
        geometry={
          nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_.geometry
        }
        material={materials["Material.009"]}
        position={[0, 1.348, 7.681]}
      />
      <mesh
        name="BezierCurve055"
        castShadow
        receiveShadow
        geometry={nodes.BezierCurve055.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE.geometry}
        material={materials["Material.002"]}
      />
      <mesh
        name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART"
        castShadow
        receiveShadow
        geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART.geometry}
        material={materials["Material.002"]}
        position={[0, 1.348, 7.681]}
      />
    </group>
  );
}

useGLTF.preload("./meshes/house2.remove.vx.colors.glb");
