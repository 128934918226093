import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o roof30.tsx крыша30.glb 
*/

import * as THREE from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { polyethylene } from "app/materials";

type GLTFResult = GLTF & {
  nodes: {
    ["10"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    ["9"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
  };
  materials: {
    ["Material.002"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/крыша30.glb") as GLTFResult;
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="10"
        geometry={nodes["10"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        position={[0, 0.001, 0]}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="9"
        geometry={nodes["9"].geometry}
        material={polyethylene}
        position={[0, 0.001, 0]}
      />
      <HoverMesh
        name="8"
        geometry={nodes["8"].geometry}
        material={materials["Material.002"]}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["Material.002"]}
        position={[0.249, 0.236, -0.936]}
        rotation={[0, 0, 0.018]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/крыша30.glb')
