import { HoverMesh } from "../c/hover-mesh";
import { HoverGroup } from "../c/hover-group"; /*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 --types --keepnames --root ./meshes -o roof40.tsx крыша40.glb 
*/

import * as THREE from "three";
import React from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { polyethylene } from "app/materials";

type GLTFResult = GLTF & {
  nodes: {
    ["1"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
  };
  materials: {
    ["scene2 - var1.002"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/крыша40.glb") as GLTFResult;
  return (
    <HoverGroup {...props} dispose={null}>
      <HoverMesh
        name="1"
        geometry={nodes["1"].geometry}
        material={materials["scene2 - var1.002"]}
      />
      <HoverMesh
        name="7"
        geometry={nodes["7"].geometry}
        material={materials["scene2 - var1.002"]}
      />
      <HoverMesh
        name="6"
        geometry={nodes["6"].geometry}
        material={polyethylene}
      />
      <HoverMesh
        name="3"
        geometry={nodes["3"].geometry}
        material={materials["scene2 - var1.002"]}
      />
      <HoverMesh
        name="4"
        geometry={nodes["4"].geometry}
        material={materials["scene2 - var1.002"]}
      />
      <HoverMesh
        name="2"
        geometry={nodes["2"].geometry}
        material={materials["scene2 - var1.002"]}
      />
      <HoverMesh
        name="5"
        geometry={nodes["5"].geometry}
        material={materials["scene2 - var1.002"]}
      />
    </HoverGroup>
  );
}

// useGLTF.preload('./meshes/крыша40.glb')
